import { FC } from "react";
import { Box, useTheme } from '@mui/material';
import { BaseErrorProps } from "./types";

/**
 * Container for pages. Use this to ensure right gapping
 *
 * @returns {JSX.Element} Container for pages content
 */
const BaseError: FC<BaseErrorProps> = ({ text = 'Error loading data' }): JSX.Element => {
    const theme = useTheme();
    return (
        <Box sx={{
            color: theme.palette.error.main
        }}>
            {text}
        </Box>
    );
};

export default BaseError;