import React, { useMemo } from "react";
import FiltersPanel from "@/components/FiltersPanel";
import { dependentAPIs, selectorsConfig } from "./consts";
import { GridColDef } from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import { styled } from "@mui/material";
import { spacing } from "@mui/system";
import { Paper as MuiPaper } from "@mui/material";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { t } from "i18next";
import MuiGridTable from "@/components/tables/DataGridTable";

const Paper = styled(MuiPaper)(spacing);

const RatingsAndReviews = () => {
  const { ratingsAndReviewsApi, isLoading } = useData();

  const rows = useMemo(() => {
    return (ratingsAndReviewsApi.data || []).map((row: { DATE: string }, index: number) => ({
      ...row,
      id: `${row.DATE}-${index}`,
    }));
  }, [ratingsAndReviewsApi?.data]);

  const allColumns = useColumns();

  const columns: GridColDef[] = [
    allColumns.dateColumn,
    allColumns.statusColumn,
    allColumns.posColumn,
    { ...allColumns.brandNameColumn, width: 150},
    { ...allColumns.productLinkColumn, flex: 1 },
    allColumns.sizeColumn,
    allColumns.pznColumn,
    allColumns.ratingStarsColumn,
    allColumns.ratingNumberColumn,
    allColumns.avgColumn,
    allColumns.ratingsColumn,
    allColumns.impactColumn,
    allColumns.authorColumn,
    allColumns.titleColumn,
    { ...allColumns.textColumn , flex: 1}
  ];


  return (
    <ContentContainer>
      <FiltersPanel
        hasDaysFilter={false}
        selectorsConfig={selectorsConfig}
        dependentAPIs={dependentAPIs} />
      <Paper>
        <MuiGridTable
          rows={rows}
          columns={columns}
          loading={isLoading}
          filterBarType={2}
          hiddenFields={['STATUS', 'AUTHOR']}
          excludedRowsOnSearch={['URL']}
          searchPlaceholder={t('tableColumns.searchPlaceholder') || 'SKU, Products, ...'}
        />
      </Paper>
    </ContentContainer>
  );
};

export default RatingsAndReviews;
