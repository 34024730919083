import React, { useMemo } from "react";
import {
  Paper,
} from "@mui/material";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { useColumns } from "@/hooks/useColumnsData";
import { selectorsConfig } from "./consts";
import ContentContainer from "@/components/contentContainer";
import CustomGridTreeDataGroupingCell from "@/components/CustomGridTreeDataGroupingCell";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import FiltersPanel from "@/components/FiltersPanel";
import useData from "@/hooks/auth/useData";

export const VisibilityShareOfSearch = () => {
  const { shareOfSearchApi } = useData();
  const columns = useColumns();

  const nodes = useMemo(() => {
    const dataWithUniqueIds = shareOfSearchApi?.data || [];
    
    return dataWithUniqueIds;
  }, [shareOfSearchApi?.data]);

    
  const groupingSovColDef: GridColDef = {
    headerName: 'Brand',
    headerClassName: 'grouping-header',
    field: 'name',
    width: 220,
    renderCell: (params: GridRenderEditCellParams) => (
      <CustomGridTreeDataGroupingCell
        {...params}
        content={params.row.type === "brand" ? params.row.name : ''}
      />
    ),     
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === 'brand' ? row.name : ''; 
    },
  };

  const myDataGridColumns = [
    columns.sovKeywordColumn,
    columns.sovProductColumn,
    columns.sovSkuColumn,
    columns.shareColumn,
    columns.top3Column,
    columns.top10Column,
    columns.avgSerpColumn,
    columns.avgRatingsColumn,
  ];

  return (
    <ContentContainer>
        <FiltersPanel
          selectorsConfig={selectorsConfig}
          dependentAPIs={["shareOfSearchApi"]}
          hasDaysFilter={true}
        />
        <Paper>
          <MuiGridTable
            filterBarType={2}
            rows={nodes}
            columns={myDataGridColumns}
            loading={shareOfSearchApi?.isLoading} // for data grid pro it does not work
            getTreeDataPath={(row) => row.hierarchy || []}
            treeData
            groupingColDef={groupingSovColDef}
            getRowClassName={(params) => {
              if (params.row.type === 'keyword' || params.row.keyword) {
                return 'keyword-row';
              } else if (params.row.type === 'product' && !params.row.hierarchy.includes('keyword')) {
                return 'product-no-keyword-row';
              }
              return '';
            }}
          />
        </Paper>
    </ContentContainer>
  );
};
