import React, { FC, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import CustomAutocomplete from "../CustomAutocomplete";
import { useTranslation } from "react-i18next";
import {
  AnalyticsRequestBody,
  FiltersPanelProps,
  OptionsList,
  SelectorConfig,
} from "./types";
import * as styles from "./styles";
import { getDayOptions, SelectorNames } from "./consts";
import { useFiltersData } from "@/hooks/useAnalyticsData";
import useData from "@/hooks/auth/useData";
import { FilterList } from "@mui/icons-material";

const FiltersPanel: FC<FiltersPanelProps> = ({
  selectorsConfig,
  dependentAPIs,
  hasMoreButton = true,
  onMoreSelectors = [],
}) => {
  const { t } = useTranslation();
  const { selectedManufacturers } = useData();

  const { commonSelectorsConfig, currentSelectors, setCurrentSelectors } =
    useFiltersData(selectorsConfig, dependentAPIs);

  const visibleSelectors = commonSelectorsConfig.filter(
    (selector) => !onMoreSelectors.includes(selector.name as SelectorNames)
  );
  const moreSelectors = commonSelectorsConfig.filter((selector) =>
    onMoreSelectors.includes(selector.name as SelectorNames)
  );

  const [isMoreVisible, setIsMoreVisible] = useState(false);

  const handleAutocompleteChange = (
    value: { value: string; label: string } | null,
    name: string
  ) => {
    setCurrentSelectors((prevSelectors) => {
      const updatedSelectors = {
        ...prevSelectors,
        [name]: (value?.value === null || value?.value === undefined) ?? "All",
      };

      if (name === "brands") {
        updatedSelectors.brandname = value ? value.label : "";
      }

      return updatedSelectors;
    });
  };

  const getSelectedOption = (field: string, optionList: OptionsList[]) =>
    optionList.find(
      (option) =>
        option.value === currentSelectors[field as keyof AnalyticsRequestBody]
    ) || { value: "All", label: "All" };

  const toggleMore = () => {
    setIsMoreVisible(!isMoreVisible);
  };

  const renderAutocompleteComponent = (
    selector: SelectorConfig,
    index: number,
    selectorsGroupName: string
  ) => (
    <CustomAutocomplete
      key={`${selectorsGroupName}-${index}`}
      label={selector.label}
      disabled={selector.disabled}
      labelColor={selector.labelColor}
      defaultValue={selector.defaultValue}
      value={
        selector.name === SelectorNames.Manufacturer
          ? {
              value: selectedManufacturers[0]?.ID.toString() ?? "",
              label: selectedManufacturers[0]?.NAME ?? "",
            }
          : getSelectedOption(selector.name, selector.options)
      }
      onChange={(_, value) =>
        selector.handleChange
          ? selector.handleChange(value)
          : handleAutocompleteChange(value, selector.name)
      }
      options={selector.options}
      disableClearable={selector.name === SelectorNames.Manufacturer}
    />
  );

  return (
    <Box sx={styles.containerStyles}>
      <Box>
        <Box sx={styles.innerContainerStyles}>
          <Box sx={styles.selectorsBoxStyles}>
            {visibleSelectors.map((selector, index) =>
              renderAutocompleteComponent(selector, index, "mainSelectors")
            )}
          </Box>
          <Box sx={styles.moreButtonContainerStyles}>
            {hasMoreButton && moreSelectors.length > 0 && (
              <Button sx={styles.moreButtonStyles} onClick={toggleMore}>
                <Box sx={styles.moreButtonStylesContent}>
                  <FilterList sx={{ height: 20, width: 20 }} />
                  {isMoreVisible
                    ? t("pages.analytics.selectors.less")
                    : t("pages.analytics.selectors.more")}
                </Box>
              </Button>
            )}
          </Box>
        </Box>
        {isMoreVisible && (
          <Box sx={{ ...styles.selectorsBoxStyles, paddingTop: "16px" }}>
            {moreSelectors.map((selector, index) =>
              renderAutocompleteComponent(selector, index, "otherSelectors")
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FiltersPanel;
