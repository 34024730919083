import { createMutationFunction } from "./mutations/createMutationFunction";
import { useApiMutation, ApiMutation } from "./useApiMutation";

export type BuyNowTransactionsApiType = ApiMutation<BuyNowTransactionResponse, BuyNowTransactionsParams>;

export interface BuyNowTransactionsParams {
    manufacturer: number[]
    from_date: number
    to_date: number
}

export interface BuyNowTransactionElement {
    key: string;
    data: { [key: string]: any };
    children: BuyNowTransactionElement[];
}

export type BuyNowTransactionResponse = BuyNowTransactionElement[];

const key = "buynow-transactions";

export const useBuyNowTransactions = () => {
    return useApiMutation<BuyNowTransactionResponse, BuyNowTransactionsParams>({
        mutationFn: createMutationFunction<BuyNowTransactionResponse, BuyNowTransactionsParams>("dashboard/buynow/transactions/"),
    });
    
};
