import { t } from "i18next";
import {
  ContentType,
  createMutationFunction,
} from "./mutations/createMutationFunction";
import { ApiMutation, ErrorCallback, useApiMutation } from "./useApiMutation";

export type SignInApiType = ApiMutation<SignInResponse, SignInParams>;

/**
 * Interface for sign-in parameters
 */
export interface SignInParams {
  username: string;
  password: string;
}

/**
 * Interface for sign-in response
 */
export interface SignInResponse {
  role: string;
}

/**
 * Custom error handling for sign-in
 * @param {ErrorCallback<SignInResponse>} params - The error callback parameters
 */
const handleCustomError = ({
  error,
  setError,
  setData,
}: ErrorCallback<SignInResponse>) => {
  const errorMessage =
    error.response && error.response.status === 401
      ? t("errors.signInError")
      : `${t("errors.globalError")}: ${error.message}`;

  setError(errorMessage);
  setData(undefined);
};

/**
 * Custom hook to handle the sign-in mutation using react-query
 * @returns {ApiMutation<SignInResponse, SignInParams>} - The mutation state and execute function
 */
export const useSignIn = () => {
  return useApiMutation<SignInResponse, SignInParams>({
    mutationFn: createMutationFunction<SignInResponse, SignInParams>(
      "signin",
      ContentType.URLENCODED
    ),
    onErrorCallback: handleCustomError,
  });
};
