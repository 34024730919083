import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiQuery } from "../useApiQuery";
import { LabelsResponse, SetupParams } from "./types";

const key = "labels";

export const useLabels = () => {
  return useApiQuery<LabelsResponse[], SetupParams>({
    key: key,
    queryFn: createQueryFunction<LabelsResponse[], SetupParams>(
      "app/setup/labels"
    ),
  });
};
