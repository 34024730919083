import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiQuery } from "../useApiQuery";
import { ComponentsParams, ComponentsResponse } from "./types";

const basePath = "app/buy-now/"

export const useComponents = () => {
  return useApiQuery<ComponentsResponse[], ComponentsParams>({
    key: "instances",
    queryFn: createQueryFunction(`${basePath}instances`),
  });
};