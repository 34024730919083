import { createMutationFunction } from "../mutations/createMutationFunction";
import { useApiMutation } from "../useApiMutation";
import { ShareOfSearchResponse, ShareOfSearchVariables } from "./types";

// Will be removed when MultiSelect and Andrey time range component are done
const transformDaysToDateRange = (variables: any) => {
  const currentDate = new Date();
  const fromDate = new Date();
  fromDate.setDate(currentDate.getDate() - variables.days);

  const toUnixTimestamp = Math.floor(currentDate.getTime() / 1000);
  const convertToNumber = (value: string | number) => {
    if (typeof value === 'string') {
      const numberValue = Number(value);
      if (!isNaN(numberValue) && value.toLowerCase() !== 'all') {
        return numberValue;
      }
    }
    return value;
  };
  
  
  const fromUnixTimestamp = Math.floor(fromDate.getTime() / 1000);
  const handledVariables = {
    merchants: convertToNumber(variables.merchant) || 'All',
    labels: convertToNumber(variables.label) || 1,
    subbrands: convertToNumber(variables.subbrand) || 'All',
    brands: convertToNumber(variables.parentBrand) || 'All',
    products: convertToNumber(variables.products) || 'All',
    focus: variables.focus,
    from: fromUnixTimestamp,
    to: toUnixTimestamp, 
  };

  return handledVariables;
};

export const useShareOfSearch = () => {
  const useShareOfSearch = useApiMutation<ShareOfSearchResponse[]>({
    mutationFn: (variables) => {
      const handledVariables = transformDaysToDateRange(variables); // should be removed in the future
      return createMutationFunction<ShareOfSearchResponse[], ShareOfSearchVariables>("app/visibility/share-of-search")(handledVariables);
    },
  });
  return useShareOfSearch;
};
