import React from "react";
import styled from "@emotion/styled";
import { formatUserNumber } from "@/utils/formatUserNumber";

interface ProgressBarProps {
  value: number;
  maxValue: number;
  color: string;
}

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const ProgressBarBackground = styled.div`
  background-color: #c7daf0;
  flex-grow: 1;
  height: 10px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  margin-right: 5px;
`;

const ProgressBarFill = styled.div<{ value: number; color: string }>`
  background-color: ${({ color }) => color};
  width: ${({ value }) => `${value}%`};
  height: 100%;
  position: absolute;
  border-radius: 3px;
  top: 0;
  left: 0;
`;

const ValueSpan = styled.span`
  text-align: right;
  white-space: nowrap;
  min-width: 50px;
  flex-shrink: 0;
`;

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  maxValue,
  color,
}) => {
  const percentValue = (value / maxValue) * 100;

  return (
    <ProgressBarWrapper>
      <ProgressBarBackground>
        <ProgressBarFill value={percentValue} color={color} />
      </ProgressBarBackground>
      <ValueSpan>{formatUserNumber(value)}</ValueSpan>
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
