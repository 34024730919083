import { useEffect, useMemo, useState } from "react";
//import DataGridTable from "@/components/tables/DataGridTable";
import { MuiGridTable as DataGridTable } from "@/components/tables/DataGridTable";
import { Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import {
  useColumns
} from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { t } from "i18next";
import { PropertiesResponse } from "@/api/buynow/types";

const Paper = styled(MuiPaper)(spacing);

const BuyNowProperties = (): JSX.Element => {
  const { selectedManufacturers, isLoading, allPropertiesApi } = useData();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const allColumns = useColumns();

  const rows: PropertiesResponse[] = useMemo(() => {
    return allPropertiesApi.data || [];
  }, [allPropertiesApi.data]);

  useEffect(() => {
    const selectedManufacturerIDs = selectedManufacturers.map((m) => m.ID);
    if (allPropertiesApi && selectedManufacturerIDs.length > 0) {
      setSelectedRows([]);
      allPropertiesApi.execute({manufacturer: selectedManufacturerIDs});
    }
  }, [selectedManufacturers]);

  const columns: GridColDef<PropertiesResponse>[] = [
    allColumns.bnTypeColumn,
    allColumns.ownerColumn,
    allColumns.propertyColumn,
    allColumns.domainColumn,
    allColumns.connectedColumn,
    allColumns.createdOnlyDateColumn,
    allColumns.updatedOnlyDateColumn,
    allColumns.actionsColumn,
  ]

  return (
    <ContentContainer>
      <Paper>
        <DataGridTable
          rows={rows}
          columns={columns}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          selectedRows={selectedRows}
          loading={isLoading}
          filterBarType={2}
          hasCheckboxes={true}
          hasControls={true}
          searchPlaceholder={t('navigation.buyNowProperties') || 'Properties'}
          tableInfo={t('tableInfo.buyNowProperties') || ''}
        />
      </Paper>
    </ContentContainer>
  );
};

export default BuyNowProperties;
