import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import { NavLink, NavLinkProps } from "react-router-dom";
import { darken } from "polished";
import { useLocation } from "react-router-dom";

import {
  Chip,
  Collapse,
  ListItemProps,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => {
  const location = useLocation();
  const toWithSearchParams = {
    pathname: typeof props.to === "string" ? props.to : props.to.pathname,
    search: location.search,
  };

  return (
    <div ref={ref}>
      <NavLink {...props} to={toWithSearchParams}/>
    </div>
  )
});

CustomRouterLink.displayName = "CustomRouterLink";

type ItemType = {
  activeclassname?: string;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  depth: number;
};

const Item = styled(ListItemButton)<ItemType>`
  height: ${(props) => props.depth && props.depth > 0 ? '36px' : '44px'};
  padding-left: ${(props) => props.depth && props.depth > 0 ? '48px' : '24px'};
  padding-right: 0;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.depth && props.depth > 0 ? 
        (props.theme.sidebar.submenuItem?.color || '#e0e0e0') : 
        props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};
    span {
      color: ${(props) => props.depth && props.depth > 0 ? 
        (props.theme.sidebar.submenuItem?.color || '#e0e0e0') : 
        props.theme.sidebar.color};
    }
  }
`;

type TitleType = {
  depth: number;
};

const Title = styled(ListItemText)<TitleType>`
  margin: 0;
  span {
    color: ${(props) => props.depth && props.depth > 0 ? 
        (props.theme.sidebar.submenuItem?.color || '#e0e0e0') : 
        props.theme.sidebar.color};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.079px;
    padding-left: ${(props) => props.depth && props.depth > 0 ? '16px' : '20px'};
    padding-right: 16px;
    opacity: ${(props) => props.depth && props.depth > 0 ? '0.6' : '1'};
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

type SidebarNavListItemProps = ListItemProps & {
  className?: string;
  depth: number;
  href: string;
  icon: React.FC<any>;
  badge?: string;
  open?: boolean;
  title: string;
  setNavPath:  React.Dispatch<React.SetStateAction<string>>;
};

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (props) => {
  const {
    title,
    href,
    setNavPath,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open = false,
  } = props;

  if (children) {
    return (
      <div>
        <Item
            depth={depth}
            onClick={() => setNavPath(href)}
        >
          {Icon && <Icon />}
          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
        onClick={() => setNavPath(href)}
      >
        {Icon && <Icon />}
        <Title depth={depth}>
          {title}
          {badge && <Badge label={badge} />}
        </Title>
      </Item>
    </React.Fragment>
  );
};

export default SidebarNavListItem;
