// Layouts
import ErrorLayout from "@mira/layouts/Error";
import AuthLayout from "../layouts/Auth";
import AuthCoverLayout from "../layouts/AuthCover";
import DashboardLayout from "../layouts/Dashboard";

// Auth components
import Page404 from "@mira/pages/auth/Page404";
import AuthGuard from "../components/guards/AuthGuard";
import SignIn from "../pages/auth/SignIn";

import Default from "@mira/pages/dashboards/Default";
import Analytics from "@/pages/analytics/Analytics";
import { VisibilityShareOfSearch } from "@/pages/visibility/ShareOfVoice";
import AiOptimizer from "@/pages/content/AiOptimizer";
import Content from "@/pages/content/contentBoard";
import { BuyNowTransactions } from "@/pages/buynow/transactions/index";
import RatingsAndReviews from "@/pages/content/RatingsAndReviews";
import Labels from "@/pages/setup/Labels";
import BuyNowProperties from "@/pages/buynow/properties";
import Keywords from "@/pages/setup/Keywords";
import BuyNowComponents from "@/pages/buynow/components";

export enum Routes {
  home = "/",
  signIn = "/auth-cover/sign-in",
  startPage = "/buy-now/dashboard",
}

const routes = [
  {
    path: Routes.home,
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Default />,
      },
      {
        path: "/buy-now/dashboard",
        element: <Analytics />,
      },
      {
        path: "/buy-now/transactions",
        element: <BuyNowTransactions />,
      },
      {
        path: "/buy-now/properties",
        element: <BuyNowProperties />,
      },
      {
        path: "/buy-now/components",
        element: <BuyNowComponents />,
      },
      {
        path: "/visibility/share-of-search",
        element: <VisibilityShareOfSearch />,
      },
      {
        path: "/content/merchants-product-catalog",
        element: <Content />,
      },
      {
        path: "/content/ai-optimizer",
        element: <AiOptimizer />,
      },
      {
        path: "/content/ratings-and-reviews",
        element: <RatingsAndReviews />,
      },
      {
        path: "/setup/labels",
        element: <Labels />,
      },
      {
        path: "/setup/keywords",
        element: <Keywords />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "auth-cover",
    element: <AuthCoverLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
