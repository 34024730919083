import React, { useState } from "react";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColDef,
  GridRowSelectionModel,
  GridActionsCellItem,
  GridToolbarProps,
  GridPaginationModel,
  GridColumnVisibilityModel,
  GridCsvExportOptions,
  GridRowId,
  GridCsvGetRowsToExportParams,
  GridRowClassNameParams,
  GridSortItem,
  GridRowParams,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";

import {
  Box,
  Stack,
  Button,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import useData from "@/hooks/auth/useData";
import { enUS, esES, deDE } from "@mui/x-data-grid/locales";
import { t } from "i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { Label } from "@/api/setup/types";
import { Interpolation, Theme } from "@emotion/react";
import { JSX } from "@emotion/react/jsx-runtime";

interface Props<T extends GridValidRowModel> {
  rows: T[];
  columns: GridColDef[];
  onRowSelectionModelChange?: (selectionModel: GridRowSelectionModel) => void;
  selectedRows?: GridRowSelectionModel;
  loading: boolean;
  hasCheckboxes?: boolean;
  filterBarType?: number;
  initialPageSize?: number;
  skeletonRowCount?: number;
  hiddenFields?: Array<string>;
  hasControls?: boolean;
  title?: string;
  searchPlaceholder?: string;
  tableInfo?: string;
  opacityTableInfo?: string;
  treeData?: boolean;
  getTreeDataPath?: (row: T) => string[];
  groupingColDef?: GridColDef;
  sortModel?: GridSortItem[];
  hasExport?: boolean;
  getRowClassName?: ((params: GridRowClassNameParams<any>) => string) | undefined;
  getDetailPanelContent?: (params: GridRowParams<T>) => React.ReactNode;
  getDetailPanelHeight?: (params: GridRowParams<T>) => number;
  excludedRowsOnSearch?: Array<string>,
  exportAllColumns?: boolean,
}

/**
 * CustomToolbar component for Mui DataGrid
 *
 * @param {GridToolbarProps} props - The properties for the CustomToolbar component.
 * @returns {JSX.Element} The rendered CustomToolbar component
 */
const CustomToolbar: React.FC<GridToolbarProps> = (
  props: GridToolbarProps
): JSX.Element => {
  return (
    <Box padding={2}>
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          slotProps={{
            /**
             * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
             */
            tooltip: { title: props.loading ? "" : "Select columns" },
            button: { disabled: props.loading },
          }}
        />
        <GridToolbarFilterButton
          slotProps={{
            /**
             * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
             */
            tooltip: { title: props.loading ? "" : "Show filters" },
            button: { disabled: props.loading },
          }}
        />
        <Box flexGrow={1} />
        <GridToolbarExport
          slotProps={{
            /**
             * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
             */
            tooltip: { title: props.loading ? "" : "Export data" },
            button: { variant: "outlined", disabled: props.loading },
          }}
        />
      </GridToolbarContainer>
    </Box>
  );
};

/**
 * CustomToolbar component for Mui DataGrid
 *
 * @param props - The properties for the CustomToolbar component.
 * @returns {JSX.Element} The rendered CustomToolbar component
 */
const CustomToolbar2: React.FC<GridToolbarProps> = ({
  customFilterValue,
  onCustomFilterChange,
  hasControls,
  selectedRows,
  searchPlaceholder,
  tableInfo,
  opacityTableInfo,
  hasExport,
  treeData,
  exportAllColumns,
  ...props
}): JSX.Element => {
  const { user } = useData();
  const language = localStorage.getItem("language") ?? "en";
  const userLanguage = language || user?.settings?.language || "en";

  const getCsvSeparator = (lang: string) => (lang === "de" ? ";" : ",");

  const csvSeparator = getCsvSeparator(userLanguage);

  const csvOptions: GridCsvExportOptions = {
    delimiter: csvSeparator,
    utf8WithBom: true,
    includeHeaders: true,
    shouldAppendQuotes: true,
    allColumns: exportAllColumns,
    getRowsToExport: (params: GridCsvGetRowsToExportParams) => {
      if (!treeData) {
        const rowIds = params.apiRef.current.getAllRowIds();
        return rowIds as GridRowId[];
      }
      const sortHierarchy = (rows: any[]) => {
        return rows.sort((a, b) => {
          const aHierarchy = a.hierarchy.join("/");
          const bHierarchy = b.hierarchy.join("/");

          return aHierarchy.localeCompare(bHierarchy);
        });
      };

      const rowIds = params.apiRef.current.getAllRowIds();

      const allRowsData = rowIds.map((id) => params.apiRef.current.getRow(id));

      const sortedRows = sortHierarchy(allRowsData);
      return sortedRows.map((row) => row.id) as GridRowId[];
    },
  };

  return (
    <Box>
      {tableInfo && (
        <Stack
          padding="16px 0"
          direction="row"
          alignItems="center"
          spacing="2px"
        >
          <InfoOutlinedIcon
            sx={{
              color: "#FFC107",
              width: "20px",
              height: "20px",
            }}
          />
          <Typography variant="body2" lineHeight="20px">
            {tableInfo}
          </Typography>
        </Stack>
      )}
      {opacityTableInfo && (
        <Typography sx={{ opacity: 0.6, paddingTop: "16px" }}>
          {opacityTableInfo}
        </Typography>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingTop="16px"
      >
        <Stack spacing="8px" direction="row">
          <TextField
            label={t("tableColumns.search")}
            variant="outlined"
            size="small"
            placeholder={searchPlaceholder}
            sx={{
              width: "200px",
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={customFilterValue}
            onChange={(e) => onCustomFilterChange(e.target.value)}
            disabled={props.loading}
          />
          <GridToolbarContainer sx={{ gap: "8px" }}>
            <GridToolbarFilterButton
              slotProps={{
                /**
                 * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
                 */
                tooltip: { title: props.loading ? "" : "Show filters" },
                button: { disabled: props.loading },
              }}
            />
            <GridToolbarColumnsButton
              slotProps={{
                /**
                 * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
                 */
                tooltip: { title: props.loading ? "" : "Select columns" },
                button: { disabled: props.loading },
              }}
            />
            {hasExport && (
              <GridToolbarExport
                csvOptions={csvOptions}
                slotProps={{
                  /**
                   * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
                   */
                  tooltip: { title: props.loading ? "" : "Export data" },
                  button: { disabled: props.loading },
                }}
              />
            )}
          </GridToolbarContainer>
        </Stack>
        {hasControls && (
          <Stack spacing="10px" direction="row">
            <Button variant="outlined" disabled={selectedRows?.length === 0}>
              Delete
            </Button>
            <Button variant="contained">Create</Button>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

const CustomToolbar3: React.FC<GridToolbarProps> = (
  props: GridToolbarProps
): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box padding={2}>
      {props.title && (
        <Typography sx={{ opacity: 0.6, padding: "8px" }}>
          {props.title}
        </Typography>
      )}
      <GridToolbarContainer>
        <GridToolbarFilterButton
          slotProps={{
            /**
             * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
             */
            tooltip: { title: props.loading ? "" : "Show filters" },
            button: {
              disabled: props.loading,
              size: "large",
              sx: { color: "#2196F3" },
            },
          }}
        />
        <GridToolbarExport
          slotProps={{
            /**
             * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
             */
            tooltip: { title: props.loading ? "" : "Export data" },
            button: {
              disabled: props.loading,
              size: "large",
              sx: { color: "#2196F3" },
            },
          }}
        />
        <Box flexGrow={1} />
        {/* <Button
          variant="outlined"
          disabled={props.loading}
          sx={{ marginLeft: "16px", color: "#000", borderColor: "#000" }}
        >
          {t("general.action")}
        </Button>
        <Button
          variant="contained"
          disabled={props.loading}
          sx={{ marginLeft: "16px", backgroundColor: "#2196F3" }}
        >
          {t("general.create")}
        </Button> */}
      </GridToolbarContainer>
    </Box>
  );
};

/**
 *  MuiGridTable component which is a separately importable custom DataGrid component
 *
 * @param props - The properties for the  MuiGridTable component.
 * @returns {JSX.Element} The rendered  MuiGridTable component
 */
export const MuiGridTable: React.FC<Props<any>> = ({
  rows,
  columns,
  onRowSelectionModelChange,
  selectedRows,
  title,
  loading,
  hasCheckboxes = false,
  filterBarType = 2,
  initialPageSize = 10,
  skeletonRowCount = 3,
  hiddenFields = [],
  hasControls = false,
  searchPlaceholder = "",
  tableInfo,
  opacityTableInfo,
  treeData,
  getTreeDataPath,
  groupingColDef,
  getRowClassName,
  sortModel,
  hasExport = true,
  getDetailPanelContent,
  getDetailPanelHeight,
  excludedRowsOnSearch,
  exportAllColumns = false,
}): JSX.Element => {
  const { user } = useData();
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const [customFilterValue, setCustomFilterValue] = useState<string>("");
  const language = localStorage.getItem("language") ?? "en";
  const userLanguage = language || user?.settings?.language || "en";

  const getLocale = (lang: string) => {
    switch (lang) {
      case "es":
        return esES;
      case "de":
        return deDE;
      default:
        return enUS;
    }
  };

  const locale = getLocale(userLanguage);

  /**
   * Create placeholder columns for DataGrid while loading to show Skeletons.
   * The original columns are modified, so that the sizes stay the same.
   *
   * @returns {GridColDef[]} Grid Column defintion with placeholders
   */
  const createColumnsPlaceholder = (): GridColDef[] => {
    const placeholder: GridColDef[] = columns.map((el: GridColDef) => {
      let newDef = {};
      if (el.field === "actions") {
        newDef = {
          getActions: () => [
            <GridActionsCellItem
              icon={<Skeleton variant="rectangular" width={22} height={22} />}
              label="Edit"
              disabled
            />,
          ],
        };
      } else {
        newDef = {
          renderCell: () => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Skeleton variant="rectangular" width="100%" height={20} />
            </Box>
          ),
          sortable: false,
          filterable: false,
        };
      }
      return {
        ...el,
        ...newDef,
      };
    });

    return placeholder;
  };

  /**
   * Create placeholder rows for DataGrid while loading to show Skeletons.
   * List objects containing only the necessary id param.
   *
   * @returns {Array<{ id: number }>} List objects with row index as id to create placeholder Skeletons
   */
  const createRowsPlaceholder = (): Array<{ id: number }> => {
    return Array.from({ length: skeletonRowCount }).map((_, rowIndex) => ({
      id: rowIndex,
    }));
  };

  /**
   * Sets state to keep track of current page size
   *
   * @param {GridPaginationModel} paginationModel - MUI Pagninaton Model
   */
  const handlePageSizeChange = (paginationModel: GridPaginationModel) => {
    setPageSize(paginationModel.pageSize);
  };

  const getToolbar = () => {
    if (filterBarType === 2) {
      return CustomToolbar2;
    }
    if (filterBarType === 3) {
      return CustomToolbar3;
    }
    return CustomToolbar;
  };

  const applyCustomFilter = (): typeof rows => {
    if (!customFilterValue) return rows;

    const lowercasedFilter = customFilterValue.toLowerCase();

    return rows.filter((row) => {
      const rowMatch = Object.entries(row).some(([key, value]) => {
        if ((excludedRowsOnSearch || []).includes(key)) return false;
        
        return String(value).toLowerCase().includes(lowercasedFilter);
      })

      const labelsMatch =
        Array.isArray(row.labels) &&
        row.labels.some((label: Label) =>
          label.label_name?.toLowerCase().includes(lowercasedFilter)
        );

      return rowMatch || labelsMatch;
    });
  };

  const columnVisibilityModel: GridColumnVisibilityModel =
    columns.reduce<GridColumnVisibilityModel>((visibilityModel, column) => {
      visibilityModel[column.field] = !hiddenFields.includes(column.field);
      return visibilityModel;
    }, {});

  return (
    <Box>
      <DataGridPremium
        groupingColDef={groupingColDef ? groupingColDef : undefined}
        treeData={treeData || false}
        getTreeDataPath={treeData ? getTreeDataPath : undefined}
        autoHeight
        sx={{
          "--DataGrid-containerBackground": "#fff",
          "--DataGrid-overlayHeight": "679px",
          minHeight: 679, //necessary to have at least the height of 10 rows. otherwise UI jumps.
          padding: "0 16px 16px 16px",
          "& .MuiDataGrid-row.keyword-row": {
            backgroundColor: "#FDFBFB",
          },
          '& .MuiDataGrid-detailPanel': {
            backgroundColor: '#fff',
          },
          '& .MuiDataGrid-row.product-no-keyword-row': {
            backgroundColor: '#F7F6F6',
          },
          "& .MuiDataGrid-columnHeader.grouping-header": {
            paddingLeft: "74px",
          },
        }}
        columns={loading ? createColumnsPlaceholder() : columns}
        rows={loading ? createRowsPlaceholder() : applyCustomFilter()}
        pagination
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: initialPageSize,
            },
          },
          columns: {
            columnVisibilityModel,
          },
          sorting: { sortModel: sortModel || [] },
        }}
        onPaginationModelChange={handlePageSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        checkboxSelection={hasCheckboxes}
        disableRowSelectionOnClick
        slots={{
          toolbar: getToolbar(),
        }}
        slotProps={{
          toolbar: {
            loading: loading,
            customFilterValue: customFilterValue,
            onCustomFilterChange: setCustomFilterValue,
            title,
            hasControls: hasControls,
            selectedRows: selectedRows,
            searchPlaceholder: searchPlaceholder,
            tableInfo: tableInfo,
            opacityTableInfo: opacityTableInfo,
            hasExport: hasExport,
            treeData: treeData,
            allColumns: exportAllColumns
          },
          pagination: {
            sx: {
              display: loading ? "none" : "flex",
            },
          },
          baseCheckbox: {
            disabled: loading,
          },
        }}
        onRowSelectionModelChange={onRowSelectionModelChange}
        localeText={locale.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={getRowClassName ? getRowClassName : undefined}
        disableChildrenSorting
        getDetailPanelContent={getDetailPanelContent ? getDetailPanelContent : undefined}
        getDetailPanelHeight={getDetailPanelHeight ? getDetailPanelHeight : undefined}
      />
    </Box>
  );
};

export default MuiGridTable;
