import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "@/hooks/auth/useAuth";
import { Routes } from "@/consts/routes";
import { DataProvider } from "@/context/DataContext";
import { PreloaderBox } from "../preloader-box/PreloaderBox";

interface AuthGuardType {
  children: React.ReactNode;
  authPath?: string;
}

function AuthGuard({ children, authPath = Routes.signIn }: AuthGuardType) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized || isAuthenticated === undefined) {
    return <PreloaderBox />;
  }

  if (!isAuthenticated) {
    return <Navigate to={authPath} />;
  }

  return <DataProvider>{children}</DataProvider>;
}

export default AuthGuard;
