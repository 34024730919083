import { AnalyticsRequestBody, DayOption } from "./types";
import { TFunction } from "i18next";

export const getDayOptions = (t: TFunction): DayOption[] => [
  { value: 7, label: `7 ${t("filters.days")}` },
  { value: 30, label: `30 ${t("filters.days")}` },
  { value: 90, label: `90 ${t("filters.days")}` },
  { value: 160, label: `160 ${t("filters.days")}` },
];

export enum SelectorNames {
  Manufacturer = "manufacturer",
  Merchant = "merchant",
  Seller = "seller",
  Brand = "brand",
  ParentBrand = "parentBrand",
  SubBrand = "subbrand",
  Products = "products",
  Source = "source",
  Medium = "medium",
  Campaign = "campaign",
  Term = "term",
  Content = "content",
  Device = "device",
  Focus = "focus",
  Url = "url",
  UtmParams = "utmParams",
  Market = 'market',
  RevStatus = "rev_status",
  Label = "label",
  Dimension = "dimension",
}

export const defaultSelectors: AnalyticsRequestBody = {
  days: 30,
  manufacturer: [],
  products: "ALL",
  brand: "ALL",
  subbrand: "ALL",
  focus: 0,
  url: "All",
  device: "ALL",
  term: "ALL",
  source: "ALL",
  medium: "ALL",
  campaign: "ALL",
  content: "ALL",
  merchant: "ALL",
  brandname: "",
  seller: "ALL",
  parentBrand: "ALL",
  market: 0,
};

export enum Breakpoints {
  XLarge = 1800,
  Large = 1700,
  MediumLarge = 1640,
  Medium = 1500,
  SmallMedium = 1360,
  Small = 1100,
}

export const selectorCountBreakpoints = [
  { value: Breakpoints.XLarge, count: 8 },
  { value: Breakpoints.Large, count: 7 },
  { value: Breakpoints.MediumLarge, count: 7 },
  { value: Breakpoints.Medium, count: 6 },
  { value: Breakpoints.SmallMedium, count: 5 },
  { value: Breakpoints.Small, count: 4 },
];
