import  { FC }  from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  Plugin
} from 'chart.js';
import ChartWrapper from '../chartWrapper';
import { BarChartProps } from './types';
import { Skeleton } from "@mui/material";
import BaseError from '@/components/errors/baseError';
import { formatUserNumber } from "@/utils/formatUserNumber";
import useData from "@/hooks/auth/useData";

// Register necessary chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

/**
 * Custom BarChart with 1 or 2 y-Axis. Works as: single bar chart, stacked bar chart,
 * and stacked bar chart for which each sub-bar starts from start point (bars behind each other)
 * 
 * @param props - The properties for the  BarChart component.
 * @returns {JSX.Element} The rendered  BarChart component
 */
const BarChart: FC<BarChartProps> = ({
  data,
  loading,
  isError,
  height = 400, 
  graphPadding = '16px',
  headerVisible = false,
  tooltip,
  title,
  yTitle = "",
  y1Title = "",
  showLegend = true,
  isAnimated = false,
  startStackingFromZero = false,
}): JSX.Element => {
  const { user } = useData();
  const numberFormat = user?.settings?.numberFormat ?? "en-US";
  const yAxis = data.datasets.map((el) => el.yAxisID);

  const stackingPlugin: Plugin<'bar'> = {
    id: 'stackingPlugin',
    beforeDatasetsDraw(chart) {
      const datasetsMeta = chart.getSortedVisibleDatasetMetas();
      const ctx = chart.ctx;

      datasetsMeta[0].data.forEach((_, index) => {
        // Create an array of bars for each tick
        const barsForTick = datasetsMeta.map((meta) => {
          const bar = meta.data[index];
          const value = chart.data.datasets[meta.index].data[index] as number;
  
          let baseY, topY;
          if (meta.yAxisID === 'y') {
            baseY = chart.scales.y.getPixelForValue(0);
            topY = chart.scales.y.getPixelForValue(value);
          } else {
            baseY = chart.scales.y1.getPixelForValue(0);
            topY = chart.scales.y1.getPixelForValue(value);
          }
  
          const height = Math.abs(topY - baseY);
  
          return {
            height, 
            bar,
            baseY,
            topY,
            yAxisID: meta.yAxisID,
          };
        });
  
        // Sort the bars by height (tallest first)
        barsForTick.sort((a, b) => b.height - a.height);
  
        // Draw each bar, starting from the tallest
        barsForTick.forEach(({ bar, baseY, topY }) => {
          // @ts-ignore
          bar.base = baseY;
          bar.y = topY;
  
          // @ts-ignore
          bar.draw(ctx);
        });
      });
  
      return false; // Prevent default drawing
    },
  };
  
  // Define the type for chart options
  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: isAnimated ? 
      {
        duration: 1000, // Enable animation when isAnimated is true
        easing: 'easeOutQuad',
      } :
      false,
    plugins: {
      legend: {
        display: showLegend,
        position: 'top',
        labels: {
          color: '#000',
          usePointStyle: true,
          pointStyle: 'rect',
          font: {
            size: 16,
            weight: 400,
          },
        },
        onClick: (_, legendItem, legend) => {
          const index = legendItem.datasetIndex || 0;
          const ci = legend.chart;
  
          const meta = ci.getDatasetMeta(index);
          const currentHidden = meta.hidden === null ? false : meta.hidden;
          const allHiddenState = ci.data.datasets.map((_, i) => ci.getDatasetMeta(i).hidden || false);
          const anyHidden = allHiddenState.some((el) => el);
          const justOneActive = allHiddenState.filter((el) => el === false).length === 1;
          
          ci.data.datasets.forEach((_, i) => {
            if(anyHidden) {
              if(justOneActive && !currentHidden) {
                ci.getDatasetMeta(i).hidden = false;
              } else if(currentHidden) {
                ci.getDatasetMeta(index).hidden = false;
              } else {
                ci.getDatasetMeta(index).hidden = true;
              }
            } else {
              ci.getDatasetMeta(i).hidden = i !== index;
            }
          });

          ci.update();
        },
        
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (context) => formatUserNumber(context.raw as number, undefined, undefined, false, numberFormat)
        }
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: '#000',
        },
        stacked: true,
      },
      y: {
        display: yAxis.includes('y'),
        type: 'linear',
        position: 'left',
        stacked: true,
        suggestedMin: 0,
        ticks: {
          precision: 0,
          color: '#000',
          callback: (value) => formatUserNumber(value, undefined, undefined, false, numberFormat)
        },
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: yTitle !== "",
          text: yTitle,
          color: '#000',
          font: {
            size: 14,
            weight: 400,
          },
        },
      },
      // Right Y-Axis
      y1: {
        display: yAxis.includes('y1'),
        type: 'linear',
        position: 'right',
        stacked: true,
        grid: {
          drawOnChartArea: false,
        },
        suggestedMin: 0,
        ticks: {
          precision: 0,
          color: '#000',
          callback: (value) => formatUserNumber(value, undefined, undefined, false, numberFormat)
        },
        title: {
          display: y1Title !== "",
          text: y1Title,
          color: '#000',
          font: {
            size: 14,
            weight: 400,
          },
        },
      },
    },
  };

  return (
    <ChartWrapper 
      height={height} 
      graphPadding={graphPadding}
      headerVisible={headerVisible}
      tooltip={tooltip}
      title={title}
      >
      {loading ?
        <Skeleton variant="rectangular" sx={{height: '100%', flexGrow: 1}} />
        :
        isError ?
          <BaseError />
        :
          <Bar data={data} options={options} plugins={startStackingFromZero ? [stackingPlugin] : undefined} />
      }
    </ChartWrapper>
  );
}

export default BarChart;
