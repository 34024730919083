import useData from "@/hooks/auth/useData";

export function formatUserNumber(
  value: number | string | Date,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number,
  showYear: boolean = true,
  numFormat: string | undefined = undefined,
) {
  let numberFormat = "en-US";
  if(numFormat) {
    numberFormat = numFormat;
  } else {
    // can not be used inside hooks or if data context not available (etc. callbacks for graphs)
    const { user } = useData();
    numberFormat = user?.settings?.numberFormat ?? "en-US";
  }

  if (typeof value === "number") {
    return new Intl.NumberFormat(numberFormat, {
      useGrouping: true,
      minimumFractionDigits: minimumFractionDigits || 0,
      maximumFractionDigits: maximumFractionDigits || 2,
    }).format(value);
  }

  if (value instanceof Date) {
    return new Intl.DateTimeFormat(numberFormat, {
      day: "numeric",
      month: "numeric",
      year: showYear ? "numeric" : undefined,
    }).format(value);
  }

  return value;
}

