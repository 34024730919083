import React, {useContext, useState, useEffect} from "react";
import {
    Box,
    Grid,
    IconButton,
    Paper,
    Typography,
    Button,
    Snackbar,
    Tooltip,
    Skeleton
} from "@mui/material";
import {Star, SwapHoriz} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {ContentCopy, Refresh} from "@mui/icons-material";
import styled from "@emotion/styled";
import {diffWords} from "diff";
import {DataContext} from "@/context/DataContext";
import {renderText} from "./utils";
import {textStyles, titleStyles} from "./styles";
import SelectorsPanel from "@/pages/content/AiOptimizer/SelectorPanel";
import useData from "@/hooks/auth/useData";

export const AiOptimizer = (): JSX.Element => {
    const {t} = useTranslation();
    const {aiOptimizerApi} = useContext(DataContext);
    const {merchantProductContentApi} = useContext(DataContext);
    const {productsApi} = useContext(DataContext);
    const {merchantsApi} = useContext(DataContext);
    const {selectedManufacturers} = useData();
    const selectedManufacturerIDs = selectedManufacturers.map((m) => m.ID);

    const [loading, setLoading] = useState({
        global: false,
        teaser: false,
        description: false,
    });

    const [generatedContent, setGeneratedContent] = useState({
        teaser: t('pages.aiOptimizer.generationHelp'),
        description: t('pages.aiOptimizer.generationHelp'),
    });

    const [originalContent, setOriginalContent] = useState({
        teaser: t('pages.aiOptimizer.originalTextHelp'),
        description: t('pages.aiOptimizer.originalTextHelp')
    });

    const [showDiff, setShowDiff] = useState({
        teaser: false,
        description: false,
    });

    const [currentSelectors, setCurrentSelectors] =
        useState(
            {
                products: "",
                merchant: "",
                keywords: []
            });

    const [copySuccess, setCopySuccess] = useState(false);
    const [copyMessage, setCopyMessage] = useState('');

    useEffect(() => {
        if (merchantProductContentApi?.data) {
            setOriginalContent({
                teaser: merchantProductContentApi.data.TEASER || "",
                description: merchantProductContentApi.data.DESCRIPTION || "",
            });
        }
    }, [merchantProductContentApi?.data]);

    useEffect(() => {
        fillOriginalContent();
        setGeneratedContent({
            teaser: t('pages.aiOptimizer.generationHelp'),
            description: t('pages.aiOptimizer.generationHelp'),
        })

    }, [currentSelectors.products, currentSelectors.merchant]);

    const fillOriginalContent = async () => {
        if (currentSelectors.products && currentSelectors.merchant) {
            await merchantProductContentApi?.execute({
                product: currentSelectors.products,
                merchant_id: currentSelectors.merchant,
            });

            if (merchantProductContentApi?.data) {
                setOriginalContent({
                    teaser: merchantProductContentApi.data.TEASER || t('pages.aiOptimizer.noTeaser'),
                    description: merchantProductContentApi.data.DESCRIPTION || t('pages.aiOptimizer.noDescription')
                });
            } else {
                setOriginalContent({
                    teaser: t('pages.aiOptimizer.noTeaser'),
                    description: t('pages.aiOptimizer.noDescription')
                });
            }

            setLoading(prev => ({...prev, global: false}));
        } else {
            setOriginalContent({
                teaser: t('pages.aiOptimizer.originalTextHelp'),
                description: t('pages.aiOptimizer.originalTextHelp')
            });
        }
    };

    const handleGenerateClick = async () => {
        setLoading({...loading, global: true});

        try {
            // Start both requests in parallel
            const descriptionPromise = aiOptimizerApi?.execute({
                PID: Number(currentSelectors.products),
                MERCHANT_ID: Number(currentSelectors.merchant),
                LABELS: currentSelectors.keywords,
                TYPE: "description"
            }).catch(error => ({error})); // Catch error and return it

            const teaserPromise = aiOptimizerApi?.execute({
                PID: Number(currentSelectors.products),
                MERCHANT_ID: Number(currentSelectors.merchant),
                LABELS: currentSelectors.keywords,
                TYPE: "teaser"
            }).catch(error => ({error})); // Catch error and return it

            // Handle each promise as it resolves
            const [descriptionResult, teaserResult] = await Promise.allSettled([descriptionPromise, teaserPromise]);

            // Handle description result
            if (descriptionResult.status === "fulfilled" && !descriptionResult.value?.error) {
                const descriptionContent = descriptionResult.value?.optimizedContent || generatedContent.description;
                setGeneratedContent(prevContent => ({
                    ...prevContent,
                    description: descriptionContent
                }));
            } else {
                setGeneratedContent(prevContent => ({
                    ...prevContent,
                    description: t("pages.aiOptimizer.generationError")
                }));
            }

            // Handle teaser result
            if (teaserResult.status === "fulfilled" && !teaserResult.value?.error) {
                const teaserContent = teaserResult.value?.optimizedContent || generatedContent.teaser;
                setGeneratedContent(prevContent => ({
                    ...prevContent,
                    teaser: teaserContent
                }));
            } else {
                setGeneratedContent(prevContent => ({
                    ...prevContent,
                    teaser: t("pages.aiOptimizer.generationError")
                }));
            }
        } catch (error) {
            console.error("Failed to generate content:", error);
            setGeneratedContent(prevContent => ({
                ...prevContent,
                description: t("pages.aiOptimizer.generationError"),
                teaser: t("pages.aiOptimizer.generationError")
            }));
        } finally {
            setLoading({...loading, global: false});
        }
    };


    const handleRefreshClick = async (type: "teaser" | "description") => {
        setLoading({...loading, [type]: true});
        try {
            const response = await aiOptimizerApi?.execute({
                PID: Number(currentSelectors.products),
                MERCHANT_ID: Number(currentSelectors.merchant),
                LABELS: currentSelectors.keywords,
                TYPE: type
            });

            if (response) {
                const newContent = {
                    ...generatedContent,
                    [type]: response.optimizedContent || generatedContent[type],
                };

                setGeneratedContent(newContent);
            }
        } catch (error) {
            console.error(`Failed to refresh ${type}:`, error);
            const newContent = {
                ...generatedContent,
                [type]: t("pages.aiOptimizer.generationError"),
            };

            setGeneratedContent(newContent);
        } finally {
            setLoading({...loading, [type]: false});
        }
    };


    const handleToggleDiff = (type: "teaser" | "description") => {
        setShowDiff((prev) => ({...prev, [type]: !prev[type]}));
    };

    const getDiffText = (original: string, generated: string) => {
        const diffResult = diffWords(original, generated);
        return diffResult.map((part: { added: any; removed: any; value: any; }, index: any) => {
            const color = part.added ? "green" : part.removed ? "red" : "black";
            return (
                <span key={index} style={{color}}>
                    {part.value}
                </span>
            );
        });
    };

    const reloadProducts = async () => {
        // Trigger the product API call based on the current manufacturer
        await productsApi?.execute(
            {
            manufacturer: selectedManufacturerIDs,
            search: "" // Pass manufacturer ID if needed
            },
            
        );
    };

    useEffect(() => {
        // This can be used to load products initially if needed
        if (selectedManufacturers.length > 0) {
            reloadProducts();
        }
    }, [selectedManufacturers]);

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopyMessage(t("general.copiedToClipBoard") as string); // Enforce string type here
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);  // Hide message after 2 seconds
        }).catch(err => {
            setCopyMessage(t("general.failedToCopy") as string); // Enforce string type here
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);  // Hide message after 2 seconds
            console.error("Could not copy text: ", err);
        });
        console.log(currentSelectors)
    };

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" mb={4} sx={{
                padding: "20px",
                backgroundColor: "#fff",
                marginBottom: "16px",
                display: "flex",
                flexDirection: "row",
            }}>
                <SelectorsPanel
                    currentSelectors={currentSelectors}
                    setCurrentSelectors={setCurrentSelectors}
                    products={productsApi.data || []}
                    merchants={Array.isArray(merchantsApi?.merchants.data) ? merchantsApi?.merchants.data : []}
                    reloadProducts={reloadProducts}  // Pass reload function
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "top",
                        marginBottom: "16px",
                        height: "40px"
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Star/>}
                        onClick={handleGenerateClick}
                        sx={{
                            backgroundColor: '#1E90FF',
                            color: '#fff',
                            padding: '8px 16px',
                            borderRadius: '8px'
                        }}
                        disabled={loading.global}
                    >
                        {t('pages.aiOptimizer.generate') as string}
                    </Button>
                </Box>
            </Box>


            {/* Teaser Row */}
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom sx={titleStyles}>
                        {t("pages.aiOptimizer.originalTeaser") as string}
                    </Typography>
                    <Paper elevation={3} sx={{padding: 2}}>
                        <Typography variant="body1" sx={textStyles}>
                            {renderText(originalContent.teaser)}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6" gutterBottom
                                    sx={{...titleStyles, color: '#2BA900'}}>
                            {t("pages.aiOptimizer.optimizedTeaser") as string}
                        </Typography>
                        <ContentActions
                            onRefresh={() => handleRefreshClick("teaser")}
                            onCopy={() => handleCopyClick(generatedContent.teaser)}
                            onToggleDiff={() => handleToggleDiff("teaser")}
                        />
                    </Box>
                    <Paper elevation={3} sx={{padding: 2}}>
                        {loading.teaser || loading.global ? <SkeletonText/> : (
                            <Typography variant="body1" sx={textStyles}>
                                {showDiff.teaser
                                    ? getDiffText(originalContent.teaser, generatedContent.teaser)
                                    : renderText(generatedContent.teaser)
                                }
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            {/* Product Description Row */}
            <Grid container spacing={4} mt={4}>
                <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom sx={titleStyles}>
                        {t("pages.aiOptimizer.originalProductDescription") as string}
                    </Typography>
                    <Paper elevation={3} sx={{padding: 2}}>
                        <Typography variant="body2" sx={textStyles}>
                            {renderText(originalContent.description)}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6" gutterBottom
                                    sx={{...titleStyles, color: '#2BA900'}}>
                            {t("pages.aiOptimizer.optimizedProductDescription") as string}
                        </Typography>
                        <ContentActions
                            onRefresh={() => handleRefreshClick("description")}
                            onCopy={() => handleCopyClick(generatedContent.description)}
                            onToggleDiff={() => handleToggleDiff("description")}
                        />
                    </Box>
                    <Paper elevation={3} sx={{padding: 2}}>
                        {(loading.description || loading.global) ? <SkeletonText/> : (
                            <Typography variant="body2" sx={textStyles}>
                                {showDiff.description
                                    ? getDiffText(originalContent.description, generatedContent.description)
                                    : renderText(generatedContent.description)}
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            {/* Snackbar for copy success message */}
            <Snackbar
                open={copySuccess}
                message={copyMessage}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            />
        </Box>
    );
};

const ContentActions = ({onRefresh, onCopy, onToggleDiff}: {
    onRefresh: () => void;
    onCopy: () => void;
    onToggleDiff: () => void
}) => {
    const {t} = useTranslation();

    return (
        <Box>
            <Tooltip title={t("pages.aiOptimizer.showDiff")}>
                <IconButton size="small" onClick={onToggleDiff}>
                    <SwapHoriz fontSize="small"/>
                </IconButton>
            </Tooltip>
            <Tooltip title={t("pages.aiOptimizer.refresh")}>
                <IconButton size="small" onClick={onRefresh}>
                    <Refresh fontSize="small"/>
                </IconButton>
            </Tooltip>
            <Tooltip title={t("general.copy")}>
                <IconButton size="small" onClick={onCopy}>
                    <ContentCopy fontSize="small"/>
                </IconButton>
            </Tooltip>
        </Box>
    );
};


const MagicLoader = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;

  & > div {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: #269400;
    border-radius: 50%;
    animation: magic-pulse 1.4s infinite both;
  }

  & > div:nth-of-type(1) {
    animation-delay: -0.32s;
  }

  & > div:nth-of-type(2) {
    animation-delay: -0.16s;
  }

  & > div:nth-of-type(3) {
    animation-delay: 0s;
  }

  @keyframes magic-pulse {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

const MagicLoaderComponent = () => (
    <MagicLoader>
        <div></div>
        <div></div>
        <div></div>
    </MagicLoader>
);

const SkeletonText = () => (
    <Box sx={{width: '100%'}}>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
    </Box>
);


export default AiOptimizer;
