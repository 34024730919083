import { t } from "i18next";
import { na } from "./consts";
import { Box, Link, Chip } from "@mui/material";
import { ColorSpan } from "./types";
import Social from '@/assets/img/type_social.png';
import Content from '@/assets/img/type_content.png';
import { ReactNode } from "react";

export const getTranslatedHeaderName = (translationKey: string, fallback: string) => {
  return t(translationKey) as string || fallback;
};

export const renderTranslatedCell = (
  rawValue: string | number,
  translationMap: Record<string | number, string>,
  colorMap: string[],
  fallbackColorIndex: number = -1
) => {
  const translatedValue = translationMap[rawValue] || rawValue;
  
  const colorIndex = Object.keys(translationMap).includes(String(rawValue))
    ? Object.keys(translationMap).indexOf(String(rawValue))
    : fallbackColorIndex;

  return renderFixedColor(translatedValue, colorIndex, colorMap);
};

export const trimUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url); 
    let hostname = parsedUrl.hostname;

    if (hostname.startsWith('www.')) {
      hostname = hostname.slice(4);
    }
    
    return hostname;
  } catch {
    return url;
  }
}


export const renderLinkCell = (url: string | null, label?: string | null) => (
  url ? (
    <Link href={url} target='_blank' underline='hover' fontWeight={700} color={"#2196F3"}>
      {label || url}
    </Link>
  ) : label || na
);

export const renderFixedColor = (value: string | number, colorIndex: number | undefined, colors: string[]) => {
  if (colorIndex !== -1 && colorIndex !== undefined) {
    return (
      <span style={{ color: colors[colorIndex] }}>
        {value}
      </span>
    );
  }
  return value;
};

export const formatDate = (field: string) => {
  return (_: any, row: Record<string, any>) => {
    const val = row[field];
    if(val) {
      try {
        const date = new Date(val);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        
        return `${day}.${month}.${year} ${hours}:${minutes}`;
      } catch {
        return na;
      }
      
    }
    return na;
  }
};


export const parseCustomDate = (value: string, separator: string[] = [' ', '.', ':']): Date => {
  if (!value) return new Date(0);

  const [datePart, timePart] = value.split(separator[0]);
  const [year, month, day] = datePart.split(separator[1]);
  const [hours, minutes] = timePart?.split(separator[2]) || ['00', '00'];

  const fullYear = year?.length === 2 ? `20${year}` : year;

  const parsedDate = new Date(`${fullYear}-${month}-${day}T${hours}:${minutes}:00`);
  return parsedDate;
};


export const renderValueSpanColor = (value: number | undefined | null, colorSpans: ColorSpan[], lowerColor: string) => {
  if(value === undefined || value === null) {
    return na
  }
  const cSpan = colorSpans.find((cs) => cs.upperLimit < value);
  if(cSpan) {
    lowerColor = cSpan.color;
  }
  return (
    <span style={{ color: lowerColor }}>
      {value}
    </span>
  )
};

export const getTypeImage = (value: number) => {
  return <Box sx={{
    width: '100%',
    height: '100%',
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <img src={value === 1 ? Social : Content} alt="Buy Now type indicator" width={18} height={18}/>
  </Box>
  
};

export const getChip = (value: string | ReactNode, color: string) => {
  return <Chip
    label={value}
    size="small"
    sx={{ backgroundColor: color, borderRadius: "100px", color: "#fff" }}/>
}