import { SelectorDef } from "@/components/FiltersPanel/types";
import { SelectItem } from "./types";
import { SelectorNames } from "@/components/FiltersPanel/consts";
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const selectorsConfig: Array<SelectorDef> = [
  { name: "merchant"},
  { name: "focus", defaultValue: 0},
  { name: "parentBrand", dependentFilters: [SelectorNames.Products, SelectorNames.SubBrand]},
  { name: "subbrand", dependentFilters: [SelectorNames.Products]},
  { name: "products"},
  { name: "label", defaultValue: 1},
];