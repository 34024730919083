import React, { FC, useState, useEffect, KeyboardEvent } from "react";
import { Box, TextField, Chip } from "@mui/material";
import { MerchantsResponse } from "@/api/useMerchants";
import useData from "@/hooks/auth/useData";
import { useTranslation } from "react-i18next";
import {
  AnalyticsRequestBody,
  OptionsList,
  ProductsResponse,
} from "@/pages/analytics/types";
import { Close as CloseIcon } from "@mui/icons-material";
import CustomAutocomplete from "@/components/CustomAutocomplete";
import {
  selectorCountBreakpoints,
  SelectorNames,
} from "@/components/FiltersPanel/consts";

interface SelectorsPanelProps {
  currentSelectors: any;
  setCurrentSelectors: React.Dispatch<React.SetStateAction<any>>;
  products: ProductsResponse[];
  merchants: MerchantsResponse[];
  reloadProducts: () => void; // Function to reload products
}

const SelectorsPanel: FC<SelectorsPanelProps> = ({
  currentSelectors,
  setCurrentSelectors,
  products,
  merchants,
  reloadProducts, // Add reloadProducts prop
}) => {
  const { t } = useTranslation();
  const { user, selectedManufacturers } = useData();

  const [visibleCount, setVisibleCount] = useState(0);
  const [inputValue, setInputValue] = useState("");

  const handleResize = () => {
    const width = window.innerWidth;

    const matchedBreakpoint = selectorCountBreakpoints.find(
      (bp) => width > bp.value
    );

    if (matchedBreakpoint) {
      setVisibleCount(matchedBreakpoint.count);
    } else {
      setVisibleCount(3);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAutocompleteChange = (
    value: { value: string; label: string } | null,
    name: string
  ) => {
    setCurrentSelectors((prevSelectors) => {
      const updatedSelectors = {
        ...prevSelectors,
        [name]: value?.value ?? "",
      };

      return updatedSelectors;
    });
  };

  const handleKeywordsKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      event.preventDefault();
      setCurrentSelectors((prevSelectors) => ({
        ...prevSelectors,
        keywords: [...(prevSelectors.keywords || []), inputValue.trim()],
      }));
      setInputValue("");
    }
  };

  const handleDeleteKeyword = (keyword: string) => {
    setCurrentSelectors((prevSelectors) => ({
      ...prevSelectors,
      keywords: prevSelectors.keywords.filter((kw: string) => kw !== keyword),
    }));
  };

  const merchantsOptions = merchants?.map((merchant) => ({
    value: merchant.ID.toString(),
    label: merchant.NAME,
    key: merchant.ID,
  }));

  const productOptions = products?.map((product) => ({
    value: product.PID.toString(),
    label: `${product.NAME} - ${product.PZN}`,
    key: product.PID,
  }));

  const getSelectedOption = (field: string, optionList: OptionsList[]) =>
    optionList.find(
      (option) =>
        option.value === currentSelectors[field as keyof AnalyticsRequestBody]
    ) || { value: "", label: "" };

  const selectors = [
    {
      label: t("filters.merchant"),
      name: SelectorNames.Merchant,
      options: merchantsOptions,
    },
    {
      label: t("filters.product"),
      name: SelectorNames.Products,
      options: productOptions,
    },
    {
      label: t("filters.keywords"),
      name: "keywords",
      renderComponent: () => (
        <Box
          sx={{
            minWidth: "250px",
          }}
        >
          {" "}
          {/* Adjust the width as needed */}
          <TextField
            label={t("filters.keywords")}
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeywordsKeyDown}
            size="small"
            placeholder={t("pages.aiOptimizer.keyword_helper")}
            fullWidth
            sx={{
              "& .MuiInputBase-root": {
                height: "40px",
                padding: "0 14px",
                display: "flex",
                alignItems: "center",
              },
              "& .MuiInputBase-input": {
                height: "inherit",
                padding: "0",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                height: "40px",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "8px",
              gap: "4px",
            }}
          >
            {currentSelectors.keywords?.map(
              (keyword: string, index: number) => (
                <Chip
                  key={index}
                  label={keyword}
                  onDelete={() => handleDeleteKeyword(keyword)}
                  deleteIcon={<CloseIcon />}
                />
              )
            )}
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
          {selectors.slice(0, visibleCount).map((selector, index) => (
            <React.Fragment key={index}>
              {selector.renderComponent ? (
                selector.renderComponent()
              ) : (
                <CustomAutocomplete
                  label={selector.label}
                  value={
                    selector.name === SelectorNames.Manufacturer
                      ? {
                        value: selectedManufacturers[0]?.ID.toString() ?? "",
                        label: selectedManufacturers[0]?.NAME ?? "",
                        }
                      : getSelectedOption(selector.name, selector.options)
                  }
                  onChange={(_, value) =>
                    handleAutocompleteChange(value, selector.name)
                  }
                  options={selector.options}
                  disableClearable={
                    selector.name === SelectorNames.Manufacturer
                  }
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SelectorsPanel;
