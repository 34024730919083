import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";

export const containerStyles: SxProps<Theme> = {
  padding: "10px",
  backgroundColor: "#fff",

};

export const innerContainerStyles: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
};

export const selectorsBoxStyles: SxProps<Theme> = {
  display: "flex",
  flexWrap: "wrap",
  gap: 5,
};

export const moreButtonContainerStyles: SxProps<Theme> = {
  display: "flex",
  height: "40px",
  gap: "10px",
  alignSelf: "flex-start"
}

export const moreButtonStyles: SxProps<Theme> = {
  alignSelf: "center",
  color: "#757575",
  border: "1px solid #C4C4C4",
  minWidth: "103px",
  height: "40px"
};

export const moreButtonStylesContent: SxProps<Theme> = {
  display: "flex",
  fontWeight: "500",
  width: "84%",
  alignItems: "center",
  justifyContent: "space-between"
};

export const filterButtonStyles: SxProps<Theme> = {
  width: "87px",
  background: "#E0E0E0",
  color: "#000",
};

export const formControlStyles: SxProps<Theme> = {
  minWidth: "120px",
  alignSelf: "flex-start",
};
