import { useProperties } from "./useProperties";
import { BuynowApiType } from "./types";
import { useComponents } from "./useComponents";

const useBuynow = (): BuynowApiType => {
    const allPropertiesApi = useProperties();
    const componentsApi = useComponents();

    return {
        ...allPropertiesApi,
        componentsApi,
    }
};

export default useBuynow;