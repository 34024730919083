import { GridColDef, GridRenderCellParams, GridActionsCellItem, GridRenderEditCellParams, GridFilterInputValue } from '@mui/x-data-grid-pro';
import LabelsCell from "@/pages/setup/Labels/LabelsCell";
import { Label } from "@/api/setup/types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { formatUserNumber } from "@/utils/formatUserNumber";
import CollaborationCell from "@/pages/setup/Labels/CollaborationCell";
import { CheckCircleOutlined, ErrorOutline } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import useData from '@/hooks/auth/useData';
import { na, PREVIEW_URL, green, red, yellow, darkRed, gray, orange, avTranslations, statusTranslations, yesNoTranslations, black } from './consts';
import { formatDate, getTranslatedHeaderName, parseCustomDate, renderLinkCell, renderTranslatedCell, renderValueSpanColor, getTypeImage, trimUrl, getChip, renderFixedColor } from './utils';

export const useColumns = () => {
  const { user } = useData();
  const userNumberFormat = user?.settings?.numberFormat || "en-US";
  const { t } = useTranslation();

  const formatValue = (
    value: number | string | Date,
    maximumFractionDigits?: number,
    minimumFractionDigits?: number,
    useTime: boolean = true,
    useHours: boolean = true
  ) => {
    // THIS MUST BE GLOBELIZED AND BEING IMPORTED INSTEAD
    if (value === undefined || value === null) return na;
    if (typeof value === "number") {
      return new Intl.NumberFormat(userNumberFormat, {
        useGrouping: true,
        minimumFractionDigits: minimumFractionDigits || 0,
        maximumFractionDigits: maximumFractionDigits || 2,
      }).format(value);
    }

    if (value instanceof Date) {
      const formattedDate = new Intl.DateTimeFormat(userNumberFormat, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        ...(useTime ? { 
            year: "2-digit", 
            month: "2-digit", 
            day: "2-digit", 
            ...(useHours ? { hour: "2-digit", minute: "2-digit" } : {}) 
          } : {})
        }).format(value);
      
      return formattedDate.replace(',', '');
    }
    return value;
  };

  const createValueGetter = (
    field: string,
    shouldBeFormatted: boolean = false,
    maximumFractionDigits: number = 0
  ) => {
    return (_: any, row: Record<string, any>) => {
      const value = row[field] || row[field.toLowerCase()];

      if (!value || (typeof value === "string" && value?.trim() === "")) {
        return na;
      }

      if (shouldBeFormatted && !isNaN(Number(value))) {
        return formatValue(Number(value), maximumFractionDigits);
      }

      const parsedDate = new Date(value);
      if (shouldBeFormatted && !isNaN(parsedDate.getTime())) {
        return formatValue(parsedDate);
      }

      if (
        typeof value === "string" &&
        /^\d{2}\.\d{2}\.\d{2} \d{2}:\d{2}$/.test(value)
      ) {
        const parsedDate = parseCustomDate(value);
        return formatValue(parsedDate);
      }

      return String(value);
    };
  };

  const shareColumn: GridColDef = {
    field: "SHARE",
    headerName: getTranslatedHeaderName("tableColumns.share", "Share"),
    width: 100,
    align: "right",
    headerAlign: "right",
    renderCell: (params: GridRenderCellParams<any>) => {
      const share = (params.row["rix"] || 0) * 100;
      return (
        <span style={{ fontWeight: "bold" }}>{formatValue(share, 1, 1)}%</span>
      );
    },
    valueGetter: (_: any, row: Record<string, any>) =>
      formatValue((row["rix"] || 0) * 100),
  };

  const top3Column: GridColDef = {
    field: "TOP3",
    headerName: "Top 3",
    width: 60,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.row?.top3 === true ? (
            <CheckCircleOutlined color="success" />
          ) : (
            <ErrorOutline color="error" />
          )}
        </Box>
      );
    },
    valueGetter: createValueGetter("top3"),
  };

  const sovKeywordColumn: GridColDef = {
    field: "keyword",
    headerName: getTranslatedHeaderName("tableColumns.keyword", "Keyword"),
    width: 130,
    align: "left",
    headerAlign: "left",
    sortable: false,
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === "keyword" ? row.name || "" : row?.keyword;
    },
    renderCell: (params: GridRenderEditCellParams) => {
      return (
        <span>
          {params.row.type === "keyword"
            ? params.row.name || ""
            : params?.row?.keyword}
        </span>
      );
    },
  };
  const sovProductColumn: GridColDef = {
    field: "product",
    headerName: getTranslatedHeaderName("tableColumns.product", "Product"),
    minWidth: 200,
    flex: 1,
    align: "left",
    headerAlign: "left",
    sortable: false,
    renderCell: (params: GridRenderEditCellParams) => {
      return params.row.type === "product" ? params.row.name : "";
    },
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === "product" ? row.name : "";
    },
  };

  const top10Column: GridColDef = {
    field: "TOP10",
    headerName: "Top 10",
    width: 60,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.row?.top10 === true ? (
            <CheckCircleOutlined color="success" />
          ) : (
            <ErrorOutline color="error" />
          )}
        </Box>
      );
    },
    valueGetter: createValueGetter("top10"),
  };

  const sovSkuColumn: GridColDef = {
    field: "sku",
    headerName: getTranslatedHeaderName("tableColumns.PZN", "PZN"),
    align: "right",
    headerAlign: "left",
    sortable: false,
    width: 80,
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === "product" ? row.pzn : "";
    },
  };

  const avgSerpColumn: GridColDef = {
    field: "AVG_SERP",
    headerName: getTranslatedHeaderName(
      "tableColumns.averageSerp",
      "Avg. SERP"
    ),
    width: 100,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams<any>) => {
      return Math.round(Number(params.row["avg-serp"]));
    },
    valueGetter: (_: any, row: Record<string, any>) => {
      return Math.round(Number(row["avg-serp"])) || "";
    },
  };

  const avgRatingsColumn: GridColDef = {
    field: "AVG_RATINGS",
    headerName: getTranslatedHeaderName(
      "tableColumns.averageRatings",
      "Avg. Ratings"
    ),
    width: 100,
    align: "center",
    headerAlign: "center",
    valueGetter: (_: any, row: Record<string, any>) => {
      return Math.round(Number(row["ratings"])) || "";
    },
  };

  const textColumn: GridColDef<any> = {
    field: "TEXT",
    headerName: getTranslatedHeaderName("tableColumns.text", "Text"),
    width: 200,
    sortable: false,
    valueGetter: createValueGetter("TEXT", false),
  };

  const impactColumn: GridColDef<any> = {
    field: "IMPACT",
    sortable: true,
    headerName: getTranslatedHeaderName("tableColumns.impact", "Impact"),
    width: 75,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const colorIndex = params.value > 0 ? 1 : params.value < 0 ? 0 : 2;
      return <span style={{ color: [red, green, black][colorIndex]}}>{params.value}%</span>
    },
    valueGetter: (_, params) => {
      const percentageValue = Math.round(parseFloat((params.IMPACT || 0).toFixed(2)) * 100);
      return percentageValue;
    },
    valueFormatter: (_, params) => {
      const formattedValue = formatValue((params.IMPACT || 0), 2, 2);
      if (formattedValue == '-0,00' || formattedValue == '0,00' || formattedValue == '-0.00' || formattedValue == '0.00') return '0';      
      return formattedValue;
    },
  };

  const avgColumn: GridColDef<any> = {
    field: "AVG",
    headerName: getTranslatedHeaderName("tableColumns.avg", "AVG"),
    width: 40,
    align: "center",
    headerAlign: "center",
    valueGetter: createValueGetter("AVG", true, 1),
  };

  const pznColumn: GridColDef<any> = {
    field: "PZN",
    headerName: getTranslatedHeaderName("tableColumns.PZN", "PZN"),
    width: 100,
    sortable: false,
    valueGetter: (params: string | null) => `${params}`,

  };

  const dateColumn: GridColDef<any> = {
    field: "DATE",
    headerName: getTranslatedHeaderName("tableColumns.date", "Date"),
    width: 80,
    renderCell: (params: GridRenderEditCellParams) =>{
      const parsedDate = parseCustomDate(params.row.DATE, ["T", "-", ":"]);
      const result = formatValue(parsedDate, undefined, undefined, true, false)
      return (
      result
    )}
  };

  const ratingStarsColumn: GridColDef<any> = {
    field: "RATING_STARS",
    headerName: getTranslatedHeaderName("tableColumns.rating", "Rating"),
    width: 100,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const rating = params.row.RATING || 0;
      const maxStars = 5;

      return (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {[...Array(maxStars)].map((_, index) => (
            <span key={index}>
              {index < Math.floor(rating) ? (
                <StarIcon
                  style={{ color: "#FFD700", fontSize: 18, marginTop: 20 }}
                />
              ) : index < rating ? (
                <StarHalfIcon
                  style={{ color: "#FFD700", fontSize: 18, marginTop: 20 }}
                />
              ) : (
                <StarBorderIcon
                  style={{ color: "#c5c5c5", fontSize: 18, marginTop: 20 }}
                />
              )}
            </span>
          ))}
        </div>
      );
    },
  };

  const ratingNumberColumn: GridColDef<any> = {
    field: "RATING",
    headerName: getTranslatedHeaderName("tableColumns.ratingStarCount", "Rating"),
    renderHeader: () => '',
    width: 40,
    align: "left",
    sortable: true,
    headerAlign: "center",
    sortComparator: (v1, v2) => v1 - v2,
  };
  
  const manufacturerNameColumn: GridColDef<any> = { 
    field: 'manufacturer_name', 
    headerName: getTranslatedHeaderName('tableColumns.maker', 'Maker'),
    width: 150,
    flex: 0,
    renderCell: (params) => {
      const manufacturer = params.row.manufacturer_name;
      return manufacturer ? <span style={{fontWeight: 500}}>{manufacturer}</span> : na;
    },
    sortable: true,
    valueGetter: createValueGetter("manufacturer_name"),
  };

  const propertyColumn: GridColDef<any> = {
    field: "url",
    headerName: getTranslatedHeaderName("tableColumns.property", "Property"),
    sortable: true,
    width: 150,
    renderCell: (params) => renderLinkCell(params.row.url, trimUrl(params.row.url)),
    valueGetter: createValueGetter('url'),
  };

  const domainColumn: GridColDef<any> = {
    field: "domain",
    headerName: getTranslatedHeaderName(
      "tableColumns.restriction",
      "Restriction"
    ),
    sortable: true,
    flex: 2,
    minWidth: 120,
    valueGetter: createValueGetter("domain"),
  };

  const demoColumn: GridColDef<any> = {
    field: "DEMO",
    headerName: getTranslatedHeaderName("tableColumns.demo", "Demo"),
    minWidth: 80,
    flex: 1,
    renderCell: (params: GridRenderCellParams) =>
      renderLinkCell(
        `${PREVIEW_URL}${PREVIEW_URL.endsWith("/") ? "" : "/"}${
          params.row.DEMO
        }`,
        "Demo"
      ),
  };

  const expiresColumn: GridColDef<any> = {
    field: "EXPIRES",
    headerName: getTranslatedHeaderName("tableColumns.expires", "Expires"),
    sortable: true,
    minWidth: 80,
    flex: 1,
    valueFormatter: (params) => {
      if (!params) return na;
      const parsedDate = parseCustomDate(params);
      return formatValue(parsedDate);
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseCustomDate(v1);
      const date2 = parseCustomDate(v2);
      return date1.getTime() - date2.getTime();
    },
  };

  const configStateColumn: GridColDef<any> = {
    field: "CONFIG_STATE",
    headerName: getTranslatedHeaderName("tableColumns.config", "Config"),
    sortable: true,
    flex: 1,
    minWidth: 80,
    valueGetter: createValueGetter("CONFIG_STATE"),
  };

  const createdColumn: GridColDef<any> = {
    field: "CREATED",
    headerName: getTranslatedHeaderName("tableColumns.created", "Created"),
    sortable: true,
    minWidth: 115,
    flex: 1,
    valueFormatter: (params) => {
      if (!params) return na;
      const parsedDate = parseCustomDate(params);
      return formatValue(parsedDate);
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseCustomDate(v1);
      const date2 = parseCustomDate(v2);
      return date1.getTime() - date2.getTime();
    },
  };

  const createdOnlyDateColumn: GridColDef<any> = {
    field: "created",
    headerName: getTranslatedHeaderName("tableColumns.created", "Created"),
    sortable: true,
    width: 115,
    valueFormatter: (params) => {
      if (!params) return na;
      const parsedDate = parseCustomDate(params, ['T', '-', ':']);      
      return formatValue(parsedDate, undefined, undefined, true);
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseCustomDate(v1, ["T", "-", ":"]);
      const date2 = parseCustomDate(v2, ["T", "-", ":"]);
      return date1.getTime() - date2.getTime();
    },
  };

  const updatedColumn: GridColDef<any> = {
    field: "UPDATED",
    headerName: getTranslatedHeaderName("tableColumns.edited", "Edited"),
    sortable: true,
    flex: 1,
    minWidth: 115,
    valueFormatter: (params) => {
      if (!params) return na;
      const parsedDate = parseCustomDate(params);
      return formatValue(parsedDate);
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseCustomDate(v1);
      const date2 = parseCustomDate(v2);
      return date1.getTime() - date2.getTime();
    },
  };

  const updatedOnlyDateColumn: GridColDef<any> = {
    field: "updated",
    headerName: getTranslatedHeaderName("tableColumns.edited", "Edited"),
    sortable: true,
    width: 115,
    valueFormatter: (params) => {
      if (!params) return na;
      const parsedDate = parseCustomDate(params, ['T', '-', ':']);      
      return formatValue(parsedDate, undefined, undefined, true);
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseCustomDate(v1, ["T", "-", ":"]);
      const date2 = parseCustomDate(v2, ["T", "-", ":"]);
      return date1.getTime() - date2.getTime();
    },
  };

  const posColumn: GridColDef<any> = {
    field: "POS",
    headerName: getTranslatedHeaderName("tableColumns.POS", "POS"),
    sortable: true,
    width: 164,
  };

  const authorColumn: GridColDef<any> = {
    field: "AUTHOR",
    headerName: getTranslatedHeaderName("tableColumns.author", "AUTHOR"),
    sortable: true,
    width: 164,
    valueGetter: createValueGetter("AUTHOR"),
  };

  const titleColumn: GridColDef<any> = {
    field: "TITLE",
    headerName: getTranslatedHeaderName("tableColumns.title", "Title"),
    sortable: true,
    width: 164,
    valueGetter: createValueGetter("TITLE"),
  };

  const brandNameColumn: GridColDef<any> = {
    field: "BRAND",
    headerName: getTranslatedHeaderName("tableColumns.brand", "Brand"),
    sortable: true,
    width: 141,
    valueGetter: createValueGetter("BRAND"),
  };

  const normColumn: GridColDef<any> = {
    field: "NORM",
    headerName: getTranslatedHeaderName("tableColumns.norm", "Norm"),
    sortable: true,
    width: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: createValueGetter("NORM"),
  };

  const statusColumn: GridColDef<any> = {
    field: "STATUS",
    headerName: getTranslatedHeaderName("tableColumns.status", "Status"),
    sortable: true,
    width: 102,
    renderCell: (params: GridRenderCellParams) => {
      const lowerCasedStatus = params.row.STATUS.toLowerCase();
      return renderTranslatedCell(lowerCasedStatus, statusTranslations, [green, red])
    },
  };

  const availabilityColumn: GridColDef<any> = {
    field: "AVAILABILITY",
    headerName: getTranslatedHeaderName(
      "tableColumns.availability",
      "Availability"
    ),
    sortable: true,
    width: 133,
    renderCell: (params: GridRenderCellParams) =>
      renderTranslatedCell(params.row.AVAILABILITY || 5, avTranslations, [
        green,
        yellow,
        red,
        darkRed,
        gray,
      ]),
  };

  const prodPageColumn: GridColDef<any> = {
    field: "PROD_PAGE",
    headerName: getTranslatedHeaderName("tableColumns.prodPage", "PROD. Page"),
    sortable: true,
    width: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams) =>
      renderTranslatedCell(params.row.PROD_PAGE, yesNoTranslations, [
        green,
        red,
      ]),
  };

  const prodLinkColumn: GridColDef<any> = {
    field: "STORE_LINK",
    headerName: getTranslatedHeaderName(
      "tableColumns.prodLink",
      "Product Link"
    ),
    sortable: true,
    valueGetter: createValueGetter("STORE_LINK"),
  };

  const priceColumn: GridColDef<any> = {
    field: "PRICE",
    headerName: getTranslatedHeaderName("tableColumns.price", "Price €"),
    sortable: true,
    width: 77,
    headerAlign: "center",
    align: "right",
    valueFormatter: (params) => (params ? formatValue(params, 2, 2) : na),
    sortComparator: (v1, v2) => {
      const isV1Fallback = typeof v1 === "string" && v1 === na;
      const isV2Fallback = typeof v2 === "string" && v2 === na;

      if (isV1Fallback && isV2Fallback) {
        return v1.localeCompare(v2);
      }

      if (isV1Fallback) return 1;
      if (isV2Fallback) return -1;

      return v1 - v2;
    },
  };

  const discColumn: GridColDef<any> = {
    field: "DISC",
    headerName: getTranslatedHeaderName("tableColumns.disc", "DISC %"),
    sortable: true,
    width: 76,
    headerAlign: "center",
    align: "right",
    valueFormatter: (params) => (params ? formatValue(params, 2, 2) : na),
    sortComparator: (v1, v2) => {
      const isV1Fallback = typeof v1 === "string" && v1 === na;
      const isV2Fallback = typeof v2 === "string" && v2 === na;

      if (isV1Fallback && isV2Fallback) {
        return v1.localeCompare(v2);
      }

      if (isV1Fallback) return 1;
      if (isV2Fallback) return -1;

      return v1 - v2;
    },
  };

  const ratingsColumn: GridColDef<any> = {
    field: "RATINGS",
    headerName: getTranslatedHeaderName("tableColumns.ratings", "Ratings"),
    sortable: true,
    align: "center",
    headerAlign: "center",
    width: 75,
    renderCell: (params: GridRenderCellParams) =>
      renderValueSpanColor(
        params.row.RATINGS,
        [
          { color: green, upperLimit: 50 },
          { color: orange, upperLimit: 20 },
        ],
        red
      ),
  };

  const ratingColumn: GridColDef<any> = {
    field: "RATING",
    headerName: getTranslatedHeaderName("tableColumns.rating", "Rating"),
    sortable: true,
    width: 73,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams) =>
      renderValueSpanColor(
        params.row.RATING,
        [
          { color: green, upperLimit: 4.5 },
          { color: orange, upperLimit: 3.5 },
        ],
        red
      ),
  };

  const packshotsColumn: GridColDef<any> = {
    field: "PACKSHOTS",
    headerName: getTranslatedHeaderName("tableColumns.packshots", "Packshots"),
    sortable: true,
    width: 119,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams) =>
      renderValueSpanColor(
        params.row.PACKSHOTS,
        [
          { color: green, upperLimit: 3 },
          { color: orange, upperLimit: 1 },
        ],
        red
      ),
  };

  const videoColumn: GridColDef<any> = {
    field: "VIDEO",
    headerName: getTranslatedHeaderName("tableColumns.video", "Video"),
    sortable: true,
    width: 119,
    headerAlign: "center",
    align: "center",
    valueGetter: createValueGetter("VIDEO"),
  };

  const packshotsDescColumn: GridColDef<any> = {
    field: "PACKSHOTS_DESC",
    headerName: getTranslatedHeaderName(
      "tableColumns.packshotsDesc",
      "Packshots Description"
    ),
    sortable: true,
    width: 119,
    valueGetter: createValueGetter("PACKSHOTS_DESC"),
  };

  const videoDescColumn: GridColDef<any> = {
    field: "VIDEO_DESC",
    headerName: getTranslatedHeaderName(
      "tableColumns.videoDesc",
      "Video Description"
    ),
    sortable: true,
    width: 119,
    valueGetter: createValueGetter("VIDEO_DESC"),
  };

  const leafletColumn: GridColDef<any> = {
    field: "LEAFLET",
    headerName: getTranslatedHeaderName("tableColumns.leaflet", "Leaflet"),
    sortable: true,
    width: 94,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams) =>
      renderTranslatedCell(params.row.LEAFLET, yesNoTranslations, [green, red]),
  };

  const descriptionColumn: GridColDef<any> = {
    field: "DESCRIPTION",
    headerName: getTranslatedHeaderName(
      "tableColumns.description",
      "Description"
    ),
    sortable: true,
    width: 260,
    valueGetter: createValueGetter("DESCRIPTION"),
  };

  const descriptionLengthColumn: GridColDef<any> = {
    field: "DESC_LENGTH",
    headerName: getTranslatedHeaderName(
      "tableColumns.descriptionLength",
      "Description Length"
    ),
    sortable: true,
    width: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: createValueGetter("DESC_LENGTH"),
  };

  const teaserColumn: GridColDef<any> = {
    field: "TEASER",
    headerName: getTranslatedHeaderName("tableColumns.teaser", "Teaser"),
    sortable: true,
    width: 260,
    valueGetter: createValueGetter("TEASER"),
  };

  const indicationColumn: GridColDef<any> = {
    field: "INDICATION",
    headerName: getTranslatedHeaderName(
      "tableColumns.indication",
      "Indication"
    ),
    sortable: true,
    width: 260,
    valueGetter: createValueGetter("INDICATION"),
  };

  const canonicalColumn: GridColDef<any> = {
    field: "CANONICAL",
    headerName: getTranslatedHeaderName("tableColumns.canonical", "Canonical"),
    sortable: true,
    width: 237,
    valueGetter: createValueGetter("CANONICAL"),
  };

  const updatedDateColumn: GridColDef<any> = {
    field: "UPDATED",
    headerName: getTranslatedHeaderName("tableColumns.edited", "Edited"),
    sortable: true,
    width: 115,
    valueGetter: formatDate("UPDATED"),
  };

  const updatedPPDateColumn: GridColDef<any> = {
    field: "UPDATED_PP",
    headerName:
      getTranslatedHeaderName("tableColumns.edited", "Edited") + " PDP",
    sortable: true,
    width: 115,
    valueGetter: formatDate("UPDATED_PP"),
  };

  const actionsColumn: GridColDef<any> = {
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<MoreVertIcon />}
        onClick={() =>
          params.row.ACCESS_ID
            ? console.log("DB ID: " + params.row.ACCESS_ID)
            : console.log(na)
        }
        label="Edit"
      />,
    ],
  };

  const sellerColumn: GridColDef<any> = {
    field: "SELLER",
    headerName: getTranslatedHeaderName("tableColumns.seller", "Seller"),
    sortable: true,
    width: 200,
    valueGetter: createValueGetter("SELLER"),
  };

  const skuColumn: GridColDef<any> = {
    field: "SKU",
    headerName: getTranslatedHeaderName("tableColumns.PZN", "PZN"),
    sortable: true,
    width: 92,
  };
  
  const productNameColumn: GridColDef<any> = {
    field: 'product_name',
    headerName: getTranslatedHeaderName('tableColumns.product', 'Product'),
    sortable: true,
    valueGetter: createValueGetter('product_name'),
    minWidth: 257,
    flex: 1,
  };

  const productColumn: GridColDef<any> = {
    field: 'PRODUCT',
    headerName: getTranslatedHeaderName('tableColumns.product', 'Product'),
    sortable: true,
    valueGetter: createValueGetter('PRODUCT'),
  };

  const productLinkColumn: GridColDef<any> = {
    ...productColumn,
    width: 353,
    renderCell: (params: GridRenderCellParams) =>
      renderLinkCell(params.row.URL, params.row.PRODUCT),
    sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
    valueGetter: createValueGetter("PRODUCT"),
  };

  const sizeColumn: GridColDef<any> = {
    field: "SIZE",
    headerName: getTranslatedHeaderName("tableColumns.size", "Size"),
    sortable: true,
    width: 75,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => <span>{params.value.replace(/\s/g, "")}</span>,
    valueGetter: createValueGetter("SIZE"),
  };

  const labelColumn: GridColDef<any> = {
    field: "name",
    headerName: getTranslatedHeaderName("tableColumns.label", "Label"),
    width: 150,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return params.row.name ? (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <Box
            sx={{
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: "gray",
              mr: "4px",
            }}
          />
          <Typography fontWeight={500}>{params.row.name}</Typography>
        </Box>
      ) : (
        "n.a."
      );
    },
  };

  const kwdLabelColumn: GridColDef<any> = {
    field: "labels",
    headerName: getTranslatedHeaderName("tableColumns.label", "Label"),
    width: 400,
    flex: 1,
    sortable: true,
    valueGetter: (params: Label[]) => {
      return params?.map((label: Label) => label.label_name).join(", ");
    },
    renderCell: (params: GridRenderCellParams) => (
      <LabelsCell labels={params.row.labels} />
    ),
    filterOperators: [
      {
        label: getTranslatedHeaderName("general.filter.contains", "contains"),
        value: "contains",
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }

          const filterValue = filterItem.value.toLowerCase();
          return (_, row) => {
            return row.labels.some((label: Label) =>
              label.label_name.toLowerCase().includes(filterValue)
            );
          };
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: "text" },
      },
      {
        label: getTranslatedHeaderName(
          "general.filter.doesNotContain",
          "does not contain"
        ),
        value: "doesNotContain",
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const filterValue = filterItem.value.toLowerCase();
          return (_, row) =>
            row.labels.every(
              (label: Label) =>
                !label.label_name.toLowerCase().includes(filterValue)
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: "text" },
      },
      {
        label: getTranslatedHeaderName("general.filter.equals", "equals"),
        value: "equals",
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const filterValue = filterItem.value.toLowerCase();
          return (_, row) =>
            row.labels.some(
              (label: Label) => label.label_name.toLowerCase() === filterValue
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: "text" },
      },
      {
        label: getTranslatedHeaderName(
          "general.filter.doesNotEqual",
          "does not equal"
        ),
        value: "doesNotEqual",
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const filterValue = filterItem.value.toLowerCase();
          return (_, row) =>
            row.labels.every(
              (label: Label) => label.label_name.toLowerCase() !== filterValue
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: "text" },
      },
      {
        label: getTranslatedHeaderName(
          "general.filter.startsWith",
          "starts with"
        ),
        value: "startsWith",
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const filterValue = filterItem.value.toLowerCase();
          return (_, row) =>
            row.labels.some((label: Label) =>
              label.label_name.toLowerCase().startsWith(filterValue)
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: "text" },
      },
      {
        label: getTranslatedHeaderName("general.filter.endsWith", "ends with"),
        value: "endsWith",
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const filterValue = filterItem.value.toLowerCase();
          return (_, row) =>
            row.labels.some((label: Label) =>
              label.label_name.toLowerCase().endsWith(filterValue)
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: "text" },
      },
      {
        label: getTranslatedHeaderName("general.filter.isEmpty", "Is empty"),
        value: "isEmpty",
        getApplyFilterFn: () => {
          return (_, row) => row.labels.length === 0;
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: "text", disabled: true },
      },
      {
        label: getTranslatedHeaderName(
          "general.filter.isNotEmpty",
          "Is not empty"
        ),
        value: "isNotEmpty",
        getApplyFilterFn: () => {
          return (_, row) => row.labels.length > 0;
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: "text", disabled: true },
      },
      {
        label: getTranslatedHeaderName("general.filter.isAnyOf", "Is any of"),
        value: "isAnyOf",
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value || !Array.isArray(filterItem.value)) {
            return null;
          }
          const filterValues = filterItem.value.map((val) => val.toLowerCase());
          return (_, row) =>
            row.labels.some((label: Label) =>
              filterValues.includes(label.label_name.toLowerCase())
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: "text" },
      },
    ],
  };
  const commentColumn: GridColDef<any> = {
    field: "comment",
    headerName: getTranslatedHeaderName("tableColumns.comment", "Comment"),
    minWidth: 300,
    flex: 1,
    sortable: true,
    valueGetter: createValueGetter("comment"),
  };

  const itemsColumn: GridColDef<any> = {
    field: "items",
    headerName: getTranslatedHeaderName("tableColumns.items", "Items"),
    width: 80,
    sortable: true,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams) => {
      return (
        <span style={{ color: "black", fontWeight: 500 }}>
          {formatUserNumber(params.value)}
        </span>
      );
    },
  };

  const typeColumn: GridColDef<any> = {
    field: "type",
    headerName: getTranslatedHeaderName("tableColumns.type", "Type"),
    width: 90,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <span style={{ color: "black", fontWeight: 500 }}>
          {formatUserNumber(params.value)}
        </span>
      );
    },
  };

  const keywordColumn: GridColDef<any> = {
    field: "keyword",
    headerName: getTranslatedHeaderName("tableColumns.keyword", "Keyword"),
    minWidth: 400,
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <span style={{ color: "black", fontWeight: 500 }}>{params.value}</span>
      );
    },
  };

  const weightColumn: GridColDef<any> = {
    field: "weight",
    headerName: getTranslatedHeaderName("tableColumns.weight", "Weight"),
    width: 80,

    sortable: true,
    valueGetter: createValueGetter("weight"),
  };

  const kwdTypeColumn: GridColDef<any> = {
    field: "type",
    headerName: getTranslatedHeaderName("tableColumns.type", "Type"),
    sortable: true,
    width: 100,
    valueGetter: createValueGetter("type"),
  };

  const idColumn: GridColDef<any> = {
    field: "id",
    headerName: getTranslatedHeaderName("tableColumns.ID", "ID"),
    width: 60,
    sortable: true,
    valueGetter: createValueGetter("id"),
  };

  const collaborationColumn: GridColDef<any> = {
    field: "collaboration",
    headerName: getTranslatedHeaderName(
      "tableColumns.collaboration",
      "Collaboration"
    ),
    width: 160,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => (
      <CollaborationCell {...params} />
    ),
  };

  const ownerLabelsColumn: GridColDef<any> = {
    field: "owner",
    headerName: getTranslatedHeaderName("tableColumns.owner", "Owner"),
    minWidth: 300,
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <span>
          {params.row.owner?.first_name} {params.row.owner?.last_name}
        </span>
      );
    },
  };

  const bnTypeColumn: GridColDef<any> = {
    field: "type",
    headerName: getTranslatedHeaderName("tableColumns.type", "Type"),
    sortable: true,
    width: 50,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) => 
      getTypeImage(params.row.type),
    valueGetter: createValueGetter('type'),
  };

  const ownerColumn: GridColDef<any> = {
    field: "owner",
    headerName: getTranslatedHeaderName("tableColumns.owner", "Owner"),
    sortable: true,
    width: 150,
  };

  const connectedColumn: GridColDef<any> = {
    field: "status",
    headerName: getTranslatedHeaderName("tableColumns.status", "Status"),
    sortable: true,
    width: 150,
    renderCell: (params: GridRenderCellParams) => 
      getChip(params.row.status === 1 ? getTranslatedHeaderName('tableColumns.connected', 'Conntected') : getTranslatedHeaderName('tableColumns.noVisits', 'no visits'), 
        params.row.status === 1 ? green : orange),
    valueGetter: (_: any, row: Record<string, any>) => row.status === 1 ? getTranslatedHeaderName('tableColumns.connected', 'Connected') : getTranslatedHeaderName('tableColumns.noVisits', 'no visits'),
  };

  const connectedStatusColumn: GridColDef<any> =   {
    ...connectedColumn,
    width: 120,
    renderCell: (params: GridRenderCellParams) => 
      getChip(params.row.status === 1 ? getTranslatedHeaderName('tableColumns.connected', 'Conntected') : getTranslatedHeaderName('tableColumns.disabled', 'disabled'), 
        params.row.status === 1 ? green : orange),
    valueGetter: (_: any, row: Record<string, any>) => row.status === 1 ? getTranslatedHeaderName('tableColumns.connected', 'Connected') : getTranslatedHeaderName('tableColumns.disabled', 'disabled')
  };
  
  return {
    connectedStatusColumn,
    idColumn,
    posColumn,
    sellerColumn,
    skuColumn,
    brandNameColumn,
    productLinkColumn,
    sizeColumn,
    normColumn,
    statusColumn,
    availabilityColumn,
    prodPageColumn,
    prodLinkColumn,
    productColumn,
    priceColumn,
    discColumn,
    ratingsColumn,
    ratingStarsColumn,
    ratingNumberColumn,
    packshotsColumn,
    videoColumn,
    packshotsDescColumn,
    videoDescColumn,
    leafletColumn,
    descriptionColumn,
    descriptionLengthColumn,
    teaserColumn,
    indicationColumn,
    canonicalColumn,
    updatedDateColumn,
    updatedPPDateColumn,
    actionsColumn,
    ratingColumn,
    createdColumn,
    updatedColumn,
    configStateColumn,
    expiresColumn,
    demoColumn,
    domainColumn,
    propertyColumn,
    manufacturerNameColumn,
    dateColumn,
    pznColumn,
    avgColumn,
    impactColumn,
    textColumn,
    labelColumn,
    commentColumn,
    itemsColumn,
    typeColumn,
    collaborationColumn,
    ownerColumn,
    ownerLabelsColumn,
    bnTypeColumn,
    connectedColumn,
    createdOnlyDateColumn,
    updatedOnlyDateColumn,
    shareColumn,
    top3Column,
    top10Column,
    avgSerpColumn,
    avgRatingsColumn,
    sovSkuColumn,
    sovKeywordColumn,
    sovProductColumn,
    keywordColumn,
    kwdTypeColumn,
    kwdLabelColumn,
    weightColumn,
    productNameColumn,
    authorColumn,
    titleColumn,
  };
};
