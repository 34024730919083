import { SelectorNames } from "@/components/FiltersPanel/consts";
import { SelectorDef } from "@/components/FiltersPanel/types";
import { DataState } from "@/types/dataContext";

export const selectorsConfig: Array<SelectorDef> = [
    { name: "merchant"},
    { name: "focus", dependentFilters: [SelectorNames.Products, SelectorNames.ParentBrand, SelectorNames.SubBrand], defaultValue: 0 },
    { name: "parentBrand", dependentFilters: [SelectorNames.Products, SelectorNames.SubBrand]},
    { name: "subbrand", dependentFilters: [SelectorNames.Products]},
    { name: "products"},
    { name: "rev_status"}
  ];
  
  export const dependentAPIs: (keyof DataState)[] = [
    "ratingsAndReviewsApi",
  ];
