export const COLUMN_WIDTHS = {
    EXPANDER: '50px',
    TIMESTAMP: '115px',
    POS: '170px',
    SKU: '80px',
    PRODUCT: '381px',
    ITEMS: '50px',
    VALUE: '80px',
    TIMELEAD: '115px',
  };
  
  export const TABLE_MIN_WIDTH = '50rem';
  export const ROWS_PER_PAGE = 5;
  export const ROWS_PER_PAGE_OPTIONS = [5, 10, 50, 100];