import {
  QueryFunctionContext,
  QueryKey,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * Interface for query return type
 * @template TResponse - The type of the response from the query
 * @template TParams - The type of the parameters for the query (optional)
 */
export interface ApiQuery<TResponse, TParams = undefined> {
  isExecuted: boolean;
  execute: (variables?: TParams, cachingEnabled?: boolean) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  data: TResponse | undefined;
}

/**
 * Interface for query parameters
 * @template TResponse - The type of the response from the query
 * @template TParams - The type of the parameters for the query (optional)
 */
interface Props<TResponse, TParams = undefined> {
  key: string;
  queryFn: (
    context: QueryFunctionContext<[QueryKey, TParams | undefined]>
  ) => Promise<TResponse>;
}

const nonAbortableRequests = ["me", "merchants", "manufacturers"];

/**
 * Custom hook to handle API queries using react-query
 * @template TResponse - The type of the response from the query
 * @template TParams - The type of the parameters for the query (optional)
 * @param {Props<TResponse, TParams>} props - The query parameters
 * @returns {ApiQuery<TResponse, TParams>} - The query state and execute function
 */
export const useApiQuery = <TResponse, TParams = undefined>({
  key,
  queryFn,
}: Props<TResponse, TParams>): ApiQuery<TResponse, TParams> => {
  const [errorMessage, setError] = useState<string | null>(null);
  const [data, setData] = useState<TResponse | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExecuted, setIsExecuted] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const location = useLocation();

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current && !nonAbortableRequests.includes(key)) {
        abortControllerRef.current.abort();
      }
    };
  }, [location.pathname]);

  /**
   * Executes the query and handles the loading state and errors
   * @param {TParams} newParams - The query parameters
   * @param {boolean} cachingEnabled - Whether caching should be enabled
   */
  const execute = async (
    newParams?: TParams,
    cachingEnabled: boolean = true
  ) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setIsExecuted(true);

    // if (abortControllerRef.current && !nonAbortableRequests.includes(key)) {
    //   abortControllerRef.current.abort();
    // }

    abortControllerRef.current = new AbortController();
    try {
      const params = new URLSearchParams();
      Object.entries({ ...newParams }).forEach(([k, value]) => {
        if (Array.isArray(value)) {
          value.forEach((val: unknown) => {
            if (val !== null && val !== undefined) {
              params.append(k, String(val));
            }
          });
        } else if (value !== null && value !== undefined) {
          params.append(k, String(value));
        }
      });
      const queryString = params.toString();

      const queryData = await queryClient.fetchQuery<TResponse>({
        queryKey: [key, queryString],
        queryFn: (context) =>
          queryFn({
            ...context,
            signal: abortControllerRef.current?.signal,
          } as QueryFunctionContext<[QueryKey, TParams | undefined]>),
        retry: 0,

        ...(cachingEnabled ? {} : { staleTime: 0 }),
      });

      setIsLoading(false);
      setError(null);
      setData(queryData);
    } catch (error) {
      setIsLoading(false);
      setError(`${t("errors.globalError")}: ${(error as AxiosError)?.message}`);
      setData(undefined);
    } finally {
      abortControllerRef.current = null;
    }
  };

  return {
    execute,
    isExecuted,
    isLoading,
    isError: !!errorMessage,
    error: errorMessage,
    data,
  };
};
