import axios from "axios";
import { QueryFunctionContext, QueryKey } from "@tanstack/react-query";
import { refresh } from "../refresh/refreshFunction";

const apiUrl = import.meta.env.VITE_BASE_SERVER_URL;

/**
 * Request function that can be reused in createQueryFunction
 *
 * @template TResponse - The type representing the response data.
 * @param {string} url - target for request
 * @param {AbortSignal | undefined} signal - abort signal
 * @returns {Promise<TResponse>} - response data
 */
const createRequest = async <TResponse>(
  url: string,
  signal: AbortSignal | undefined
): Promise<TResponse> => {
  // Sending the GET request to the specified endpoint with optional query parameters
  const response = await axios.get<TResponse>(url, {
    withCredentials: true,
    signal: signal, // Allows the request to be canceled if needed
  });

  if (!response.data) {
    throw new Error("No data found");
  }

  return response.data;
};

/**
 * Function to create a query function for fetching data via GET requests.
 * This function is intended for retrieving data from an API endpoint.
 *
 * @template TResponse - The type representing the response data.
 * @template TParams - The type representing the query parameters (optional).
 * @param {string} endpoint - The specific API endpoint to send the GET request to.
 * @returns {function} - A query function compatible with react-query's useQuery, designed for GET requests.
 */
export const createQueryFunction = <TResponse, TParams = undefined>(
  endpoint: string
) => {
  return async (
    context: QueryFunctionContext<[QueryKey, TParams | undefined]>
  ): Promise<TResponse | undefined> => {
    const [, params] = context.queryKey;

    const queryString = params
      ? `${endpoint.includes("?") ? "&" : "?"}${params.toString()}`
      : "";
    const url = `${apiUrl}/${endpoint}${queryString}`;

    try {
      const response = await createRequest<TResponse>(url, context.signal);
      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        try {
          await refresh(context.signal);
          const response = await createRequest<TResponse>(url, context.signal);
          return response;
        } catch (retryError: unknown) {
          throw retryError;
        }
      }
      if (!context.signal?.aborted) {
        throw error;
      }
    }
  };
};
