import { SelectorDef } from "@/components/FiltersPanel/types";
import { SelectorNames } from "@/components/FiltersPanel/consts";
import { DataState } from "@/types/dataContext";
  
export const selectorsConfig: SelectorDef[] = [
  { name: "merchant", dependentFilters: [SelectorNames.Seller]},
  { name: "seller" },
  { name: "market", disabled: true},
  { name: "focus", dependentFilters: [SelectorNames.Products, SelectorNames.ParentBrand, SelectorNames.SubBrand], defaultValue: 0 },
  { name: "parentBrand", dependentFilters: [SelectorNames.Products, SelectorNames.SubBrand]},
  { name: "subbrand", dependentFilters: [SelectorNames.Products]},
  { name: "products"},
];
  
export const dependentAPIs: (keyof DataState)[] = [
  "contentTableApi",
];

export const hiddenTableColumns = [
  'CANONICAL',
  'DESC_LENGTH',
  'NORM',
  'UPDATED_PP',
  'UPDATED',
  'VIDEO_DESC',
  'PACKSHOTS_DESC',
  'STORE',
  'SELLER',
  'STATUS'
]