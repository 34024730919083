import styled from "@emotion/styled";
import React, { useEffect } from "react";
import {
  Grid,
  Paper as MuiPaper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { spacing } from "@mui/system";
import ProgressBar from "@/pages/analytics/components/ProgressBar";
import { formatUserNumber } from "@/utils/formatUserNumber";
import { AnalyticHeadCellIds } from "@/pages/analytics/constants";

interface Props {
  initialSortBy: string;
  headCells: HeadCell[];
  rows: RowType[];
  itemCount?: number;
  progressBar?: string;
  simplePagination?: boolean;
  isLoading?: boolean;
}

const Paper = styled(MuiPaper)(spacing);

export type RowType = {
  [key: string]: any;
};

export type HeadCell = {
  id: string;
  disablePadding?: boolean;
  label: string;
};

function descendingComparator(a: RowType, b: RowType, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
    : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<RowType>,
  comparator: (a: RowType, b: RowType) => number
) {
  const stabilizedThis = array.map((el: RowType, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

type EnhancedTableHeadProps = {
  order: "desc" | "asc";
  orderBy: string;
  onRequestSort: (e: any, property: string) => void;
  headCells: HeadCell[];
};

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTable: React.FC<Props> = (props) => {
  const {
    initialSortBy,
    headCells,
    rows,
    itemCount,
    progressBar,
    simplePagination,
    isLoading,
  } = props;
  const [order, setOrder] = React.useState<"desc" | "asc">("desc");
  const [orderBy, setOrderBy] = React.useState(initialSortBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(itemCount || 10);
  const [maxValue, setMaxValue] = React.useState(0);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  useEffect(() => {
    if (progressBar) {
      const maxValue = Math.max(...rows.map((row) => row[progressBar]));
      setMaxValue(maxValue);
    }
  }, [rows]);

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {isLoading
                ? Array.from(new Array(rowsPerPage)).map((_, index) => (
                    <TableRow key={index}>
                      {headCells.map((headCell) => (
                        <TableCell key={headCell.id}>
                          <Skeleton variant="rectangular" height={30} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          {headCells.map((headCell) => (
                            <TableCell key={headCell.id} id={labelId}>
                              {headCell.id === AnalyticHeadCellIds.CR ? (
                                row.CR
                              ) : headCell.id === progressBar ? (
                                <ProgressBar
                                  maxValue={maxValue}
                                  value={row[headCell.id]}
                                  color="blue"
                                />
                              ) : headCell.id === AnalyticHeadCellIds.Leads ? (
                                formatUserNumber(row[headCell.id])
                              ) : (
                                row[headCell.id]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={headCells.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={simplePagination ? [] : [5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const AdvancedTable: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable {...props} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AdvancedTable;
