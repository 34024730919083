import React, { useEffect, useRef, useState } from "react";
import { TextField, PopperProps, Box, dividerClasses } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { CustomAutocompleteProps } from "./types";

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
  label,
  value,
  onChange,
  options,
  disableClearable = false,
  disabled = false,
  labelColor,
  defaultValue = "All",
}) => {
  const isAllNotPossible = defaultValue.toString().toLowerCase() !== "all";
  const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));
  const allOptions = isAllNotPossible
    ? sortedOptions
    : [{ value: "All", label: "All", key: -1 }, ...sortedOptions];
  const isValueSelectedByUser = value?.value !== defaultValue;
  const [inputWidth, setInputWidth] = useState(89);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const getOptionColor = (optionValue: string) => {
    const matchedOption = options.find((opt) => opt.value === optionValue);
    return matchedOption?.coloredCircle || "";
  };
  const selectedOptionColor = getOptionColor(value?.value || "");

  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(
        spanRef.current.offsetWidth + 10 < 89
          ? 89
          : spanRef.current.offsetWidth + 10
      );
    }
  }, [inputValue]);

  return (
    <Autocomplete
      value={value}
      disabled={disabled}
      onChange={onChange}
      options={allOptions}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option?.value == value?.value}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <Box position={"relative"}>
          <span
            ref={spanRef}
            style={{
              position: "absolute",
              visibility: "hidden",
              whiteSpace: "pre",
              paddingRight: "50px",
              maxWidth: "200px",
            }}
          >
            {inputValue}
          </span>
          <TextField
            {...params}
            inputRef={inputRef}
            label={label}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            InputProps={{
              ...params.InputProps,
              startAdornment:
                label === "Label" && selectedOptionColor && !inputFocused ? (
                  <>
                    <span
                      style={{
                        marginRight: 3,
                      }}
                    />
                    <span
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        backgroundColor: selectedOptionColor,
                        marginRight: 2,
                      }}
                    />
                    <span />
                  </>
                ) : null,
            }}
            sx={{
              width: `${selectedOptionColor ? inputWidth + 10 : inputWidth}px`,
              maxWidth: "200px",
              display: "flex",
              gap: "100px",
              "& .MuiInputBase-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                top: "3px",
                color: labelColor,
                transition: "none",
              },

              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: isValueSelectedByUser ? "#757575" : undefined,
              },
            }}
          />
        </Box>
      )}
      renderOption={(props, option, { selected, index }) => (
        <li {...props} key={`${option.label}-${index}`}>
          {label === "Label" && (
            <span
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: getOptionColor(option.value || ""),
                marginRight: 8,
              }}
            />
          )}
          {option.label}
        </li>
      )}
      size="small"
      disableClearable={disableClearable}
      sx={{
        maxWidth: "200px",
        width: `${inputWidth}px`,
        "& .MuiAutocomplete-root": {
          maxWidth: "200px !important",
          minWidth: "89px !important",
        },
        "& .MuiInputBase-input": {
          paddingLeft: "2px !important",
        },
        "& .MuiSvgIcon-root": {
          width: "16px",
          height: "16px",
        },
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root":
          {
            paddingRight: "40px !important",
          },
      }}
      PopperComponent={(props: PopperProps) => (
        <Popper
          {...props}
          style={{ width: "fit-content" }}
          placement="bottom-start"
        />
      )}
    />
  );
};

export default CustomAutocomplete;
