import { useContentTable } from "./useContentTable";
import { ContentApiType } from "./types";
import { useRatingsAndReviews } from "./useRatingsAndReviews";

const useContent = (): ContentApiType => {
    const contentTableApi = useContentTable();
    const ratingsAndReviewsApi = useRatingsAndReviews();

    return {
        contentTableApi,
        ratingsAndReviewsApi
    }
};

export default useContent;