import { useEffect, useMemo, useState } from "react";
import { MuiGridTable as DataGridTable } from "@/components/tables/DataGridTable";
import { Alert, Paper as MuiPaper, Snackbar } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel } from "@mui/x-data-grid";
import useData from "@/hooks/auth/useData";
import {
  useColumns
} from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { t } from "i18next";
import { ComponentsResponse } from "@/api/buynow/types";
import { getTypeImage, renderLinkCell, trimUrl } from "@/hooks/useColumnsData/utils";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { na } from "@/hooks/useColumnsData/consts";

const Paper = styled(MuiPaper)(spacing);

const BuyNowComponents = (): JSX.Element => {
  const { selectedManufacturers, componentsApi } = useData();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const allColumns = useColumns();
  const snackbarMessage = t('tableColumns.copiedToClipboard');
  const rows: ComponentsResponse[] = useMemo(() => {
    console.log(componentsApi.data);
    return componentsApi.data || [];
  }, [componentsApi.data]);

  useEffect(() => {
    const selectedManufacturerIDs = selectedManufacturers.map((m) => m.ID);
    if (componentsApi && selectedManufacturerIDs.length > 0) {
      setSelectedRows([]);
      componentsApi.execute({manufacturer: selectedManufacturerIDs});
    }
  }, [selectedManufacturers]);

  const handleCopy = (domain: string) => {
    const divContent = `<div cid=${domain}></div>`;
    navigator.clipboard.writeText(divContent);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  
  const columns: GridColDef<ComponentsResponse>[] = [
    {
      ...allColumns.idColumn,
      headerName: "ID",
      width: 80,
      renderCell: (params) => {
        const componentId = params.row.id;
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 0,
              cursor: "pointer",
            }}
            onClick={() => handleCopy(componentId)}
          >
            <ContentCopyIcon style={{ height: 14 }} />
            <span>{componentId ? componentId : na}</span>
          </div>
        )
      },
    },
    allColumns.manufacturerNameColumn,
    allColumns.productNameColumn,
    {
      ...allColumns.pznColumn,
      align: 'center',
      headerAlign: 'center',
      width: 90
    },
    allColumns.connectedStatusColumn,
    { 
      ...allColumns.bnTypeColumn,
      width: 54,
      renderCell: (params: GridRenderCellParams) => getTypeImage(params.row.install.type),
    },
    { 
      ...allColumns.propertyColumn, 
      width: 150, 
      renderCell: (params) => renderLinkCell(params.row.install?.url, trimUrl(params.row.install?.url)),
      valueGetter: (_: any, row: Record<string, any>) => row.install?.url || na,
    },
    allColumns.createdOnlyDateColumn,
    allColumns.updatedOnlyDateColumn,
  ]

  return (
    <ContentContainer>
      <Paper>
        <DataGridTable
          rows={rows}
          columns={columns}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          selectedRows={selectedRows}
          loading={componentsApi.isLoading}
          filterBarType={2}
          hasCheckboxes={true}
          searchPlaceholder={t('tableColumns.searchPlaceholder') || 'SKU, Products, ...'}
          tableInfo={t('tableInfo.buyNowComponents') || ''}
        />
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ContentContainer>
  );
};

export default BuyNowComponents;
