import { defaultSelectors } from "./../components/FiltersPanel/consts";
import { useState, useEffect, useRef, useMemo } from "react";
import useData from "@/hooks/auth/useData";
import {
  AnalyticsRequestBody,
  SelectorDef,
} from "@/components/FiltersPanel/types";
import { useMerchants } from "@/api/useMerchants";
import { useTranslation } from "react-i18next";
import { SelectorConfig } from "@/components/FiltersPanel/types";
import { debounce } from "@mui/material";
import { SelectorNames } from "@/components/FiltersPanel/consts";
import { useLocation, useNavigate } from "react-router-dom";
import { DataState } from "@/types/dataContext";

type EventType = { value: string; label: string };

export const useFiltersData = (
  selectorsConfig: SelectorDef[],
  dependentAPIs: (keyof DataState)[]
) => {
  const {
    manufacturersApi,
    user,
    selectedManufacturers,
    dateRange,
    daysChanged,
    ...analyticsApis
  } = useData();
  const { merchants } = useMerchants();
  const { data: merchantsData, execute: merchantsExecute } = merchants;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const hasExecutedInitialLoad = useRef(false);
  const hasManuallyChanged = useRef(false);
  const manufacturersData = manufacturersApi?.manufacturers?.data || [];
  const utmParamsNames = [
    SelectorNames.Medium,
    SelectorNames.Campaign,
    SelectorNames.Term,
    SelectorNames.Content,
    SelectorNames.Device,
    SelectorNames.Url,
  ];
  const hasManufacturerSelector = selectorsConfig.find(
    (sc) => sc.name === SelectorNames.Manufacturer
  );
  const selectedManufacturerIDs = selectedManufacturers.map((m) => m.ID);

  const getDefaultSelectorValue = (
    defaultValue?: string | number
  ): string | number => {
    return defaultValue !== undefined ? defaultValue : "ALL";
  };

  const defaultSelectors: Record<string, string | number> =
    selectorsConfig.reduce((acc, selector) => {
      acc[selector.name] = getDefaultSelectorValue(selector.defaultValue);
      return acc;
    }, {} as Record<string, string | number>);

  const hasProductsAndBrand =
    selectorsConfig.some((selector) => selector.name === "products") &&
    selectorsConfig.some((selector) => selector.name === "brand");

  if (hasProductsAndBrand) {
    defaultSelectors["brandname"] = "";
  }

  const getURLParams = () => {
    const params = new URLSearchParams(window.location.search);

    const urlParams: Record<string, string | number> = {};

    const queryParams = selectorsConfig.map((sc) => sc.name);

    queryParams.forEach((param) => {
      const value = params.get(param);
      if (value !== null) {
        urlParams[param] =
          typeof defaultSelectors[param as keyof AnalyticsRequestBody] ===
          "number"
            ? Number(value)
            : value;
      }
    });

    return urlParams;
  };

  const [currentSelectors, setCurrentSelectors] =
    useState<AnalyticsRequestBody>({
      ...defaultSelectors,
      ...getURLParams(),
      from_date: dateRange.from_date,
      to_date: dateRange.to_date,
    });

  const executeApi = (
    selectorName: string,
    selectors: AnalyticsRequestBody
  ) => {
    const selectorManufacturer = Array.isArray(selectors.manufacturer)
      ? selectors.manufacturer
      : [selectors.manufacturer || 0];
    const manufacturer = hasManufacturerSelector
      ? selectorManufacturer
      : selectedManufacturerIDs;

    switch (selectorName) {
      case SelectorNames.Brand:
        return analyticsApis.brandsApi.execute({
          manufacturer: manufacturer,
        });
      case SelectorNames.Products:
        return analyticsApis.productsApi.execute({
          manufacturer: manufacturer,
          search: selectors.brandname || "",
          brand: selectors.parentBrand || "ALL",
          subbrand: selectors.subbrand || "ALL",
          focus: selectors.focus || 0,
        });
      case SelectorNames.ParentBrand:
        return analyticsApis.parentBrandsApi?.execute({
          manufacturer: manufacturer,
          focus: selectors.focus || 0,
        });
      case SelectorNames.SubBrand:
        return analyticsApis.subBrandsApi?.execute({
          manufacturer: manufacturer,
          brand: selectors.parentBrand,
          focus: selectors.focus || 0,
        });
      case SelectorNames.Seller:
        return analyticsApis.sellerApi?.execute({
          manufacturer: manufacturer,
          merchants: selectors.merchant,
        });
      case SelectorNames.Label:
        return analyticsApis.labelsApi?.execute();
      case SelectorNames.UtmParams:
        return analyticsApis.utmParamsApi.execute({
          manufacturer_id: manufacturer,
        });
      default:
        return Promise.resolve();
    }
  };

  useEffect(() => {
    const params = new URLSearchParams();

    Object.keys(currentSelectors).forEach((key) => {
      const typedKey = key as keyof AnalyticsRequestBody;
      if (
        key !== "manufacturer" &&
        key !== "brandname" &&
        key !== "from_date" &&
        key !== "to_date" &&
        key !== "days" &&
        currentSelectors[typedKey] !== defaultSelectors[typedKey]
      ) {
        params.append(
          key,
          currentSelectors[typedKey]
            ? (currentSelectors[typedKey] || "").toString()
            : ""
        );
      }
    });

    if (dateRange?.days) {
      params.append("days", dateRange.days.toString());
    }

    const paramString = params.toString();

    const url = paramString
      ? `${window.location.pathname}?${paramString}`
      : window.location.pathname;

    navigate(url, { replace: true });
  }, [currentSelectors, dateRange.days]);

  const fetchData = async (updatedSelectors: AnalyticsRequestBody) => {
    try {
      setIsLoading(true);
      await Promise.all(
        selectorsConfig.map((selector) => {
          const selectorName = utmParamsNames.includes(
            selector.name as SelectorNames
          )
            ? SelectorNames.UtmParams
            : selector.name;
          return executeApi(selectorName, updatedSelectors);
        })
      );
      merchantsExecute();
      fetchDependentAPIs(updatedSelectors);
    } catch (error) {
      console.error("API call failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedManufacturers.length > 0 &&
      (!hasExecutedInitialLoad.current || !hasManufacturerSelector) &&
      !daysChanged.current
    ) {
      let updatedSelectors = {
        ...currentSelectors,
        manufacturer: selectedManufacturers[0].ID || 0,
        from_date: dateRange.from_date,
        to_date: dateRange.to_date,
      };

      // only happens if no manufacturers filter and top level manufacturer changed
      if (hasExecutedInitialLoad.current) {
        updatedSelectors = {
          ...defaultSelectors,
          brandname: "",
          manufacturer: selectedManufacturers[0].ID || 0,
          from_date: dateRange.from_date,
          to_date: dateRange.to_date,
        };
      }

      setCurrentSelectors(updatedSelectors);

      fetchData(updatedSelectors);

      hasExecutedInitialLoad.current = true;
    }
  }, [selectedManufacturers, dateRange.from_date]);

  useEffect(() => {
    if (dateRange && daysChanged.current) {
      const updatedSelectors = {
        ...currentSelectors,
        from_date: dateRange.from_date,
        to_date: dateRange.to_date,
      };

      setCurrentSelectors(updatedSelectors);

      fetchData(updatedSelectors);

      daysChanged.current = false;
    }
  }, [dateRange]);

  const fetchDependentAPIs = async (selectors: AnalyticsRequestBody) => {
    if (!hasManufacturerSelector) {
      selectors = {
        ...selectors,
        manufacturer: selectedManufacturerIDs,
      };
    }
    try {
      setIsLoading(true);
      await Promise.all(
        // @ts-ignore
        dependentAPIs.map((api) => analyticsApis[api]?.execute(selectors))
      );
    } catch (error) {
      console.error("Filter API call failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = debounce(
    async (
      selector: Array<string>,
      data: EventType,
      field: keyof AnalyticsRequestBody
    ) => {
      const defaultSelectorValue =
        defaultSelectors[field as keyof AnalyticsRequestBody];
      const updatedSelectors = {
        ...currentSelectors,
        [field]:
          (data?.value === "All" ? defaultSelectorValue : data?.value) ||
          defaultSelectorValue,
        ...(field === "brand" && {
          brandname: data?.label === "All" ? "" : data?.label,
        }),
      };

      setCurrentSelectors(updatedSelectors);

      await Promise.all(
        selector.map((api) => executeApi(api, updatedSelectors))
      ).then(() => {
        hasManuallyChanged.current = true;
      });
    },
    300
  );

  const handleManufacturerChange = async (
    value: { value: string; label: string }[] | null,
    name: string
  ) => {
    const updatedSelectors = { ...currentSelectors };

    if (name === SelectorNames.Manufacturer) {
      updatedSelectors.manufacturer = Number(value);
      updatedSelectors.brandname = "";
    }

    setCurrentSelectors(updatedSelectors);

    await Promise.all(
      selectorsConfig.map((selector) => {
        const selectorName = utmParamsNames.includes(
          selector.name as SelectorNames
        )
          ? SelectorNames.UtmParams
          : selector.name;
        return executeApi(selectorName, updatedSelectors);
      })
    );
    await fetchDependentAPIs(updatedSelectors);
  };

  // useEffect(() => {
  //   // await the filters
  //   if (hasExecutedInitialLoad.current) {
  //     fetchDependentAPIs({
  //       ...(!currentSelectors.brandname && { brandname: "" }),
  //       ...currentSelectors,
  //     });
  //   }
  // }, [currentSelectors]);

  // @ts-ignore
  const commonSelectorsConfig: SelectorConfig[] = useMemo(() => {
    return selectorsConfig.map((selector) => {
      switch (selector.name) {
        case SelectorNames.Manufacturer:
          return {
            label: t("filters.manufacturer"),
            name: SelectorNames.Manufacturer,
            options: manufacturersData.map((manufacturer) => ({
              value: manufacturer.ID.toString(),
              label: manufacturer.NAME,
              key: manufacturer.ID,
            })),
            handleChange: handleManufacturerChange,
          };
        case SelectorNames.Merchant:
          return {
            label: t("filters.merchant"),
            name: SelectorNames.Merchant,
            options: (Array.isArray(merchantsData) ? merchantsData : []).map(
              (merchant) => ({
                value: merchant.ID.toString(),
                label: merchant.NAME,
                key: merchant.ID,
              })
            ),
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [SelectorNames.Brand],
                event,
                SelectorNames.Merchant
              ),
            dependentOn: SelectorNames.Manufacturer,
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Brand:
          return {
            label: t("filters.brand"),
            name: SelectorNames.Brand,
            options:
              analyticsApis.brandsApi.data?.map((brand) => ({
                value: brand.ID.toString(),
                label: brand.BRAND,
                key: brand.ID,
              })) || [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [SelectorNames.Products],
                event,
                SelectorNames.Brand
              ),
            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Products:
          return {
            label: t("filters.product"),
            name: SelectorNames.Products,
            options:
              analyticsApis.productsApi.data?.map((product) => ({
                value: product.PID.toString(),
                label: `${product.NAME} - ${product.PZN}`,
                key: product.PID,
              })) || [],
            dependentOn: SelectorNames.Brand,
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Products
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Focus:
          return {
            label: t("filters.focus"),
            name: SelectorNames.Focus,
            options: [
              {
                value: 0,
                label: t("pages.analytics.selectors.no"),
                key: 0,
              },
              {
                value: 1,
                label: t("pages.analytics.selectors.yes"),
                key: 1,
              },
            ],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Focus
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue || 0,
          };
        case SelectorNames.RevStatus:
          return {
            label: t("filters.revStatus"),
            name: SelectorNames.RevStatus,
            options: [
              {
                value: "active",
                label: "Active",
                key: 1,
              },
              {
                value: "deleted",
                label: "Deleted",
                key: 2,
              },
            ],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.RevStatus
              ),
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Brand:
          return {
            label: t("filters.brand"),
            name: SelectorNames.Brand,
            options:
              analyticsApis.brandsApi.data?.map((brand) => ({
                value: brand.ID.toString(),
                label: brand.BRAND,
                key: brand.ID,
              })) || [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [SelectorNames.Products],
                event,
                SelectorNames.Brand
              ),
            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Products:
          return {
            label: t("filters.product"),
            name: SelectorNames.Products,
            options:
              analyticsApis.productsApi.data?.map((product) => ({
                value: product.PID.toString(),
                label: `${product.NAME} - ${product.PZN}`,
                key: product.PID,
              })) || [],
            dependentOn: SelectorNames.Brand,
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Products
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Focus:
          return {
            label: t("filters.focus"),
            name: SelectorNames.Focus,
            options: [
              {
                value: 1,
                label: t("pages.analytics.selectors.yes"),
                key: 1,
              },
              {
                value: 0,
                label: t("pages.analytics.selectors.no"),
                key: 0,
              },
            ],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Focus
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue || 0,
          };
        case SelectorNames.RevStatus:
          return {
            label: t("filters.revStatus"),
            name: SelectorNames.RevStatus,
            options: [
              {
                value: "active",
                label: "Active",
                key: 1,
              },
              {
                value: "deleted",
                label: "Deleted",
                key: 2,
              },
            ],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.RevStatus
              ),
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Source:
          return {
            label: t("filters.source"),
            name: SelectorNames.Source,
            options: analyticsApis.utmParamsApi.data
              ? analyticsApis.utmParamsApi.data.utm_sources.map((source) => ({
                  value: source.UTM_SOURCE_ID.toString(),
                  label: source.UTM_SOURCE,
                  key: source.UTM_SOURCE_ID,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Source
              ),
            labelColor: selector?.labelColor || "#59a74f",
            dependentOn: SelectorNames.Manufacturer,
          };
        case SelectorNames.Medium:
          return {
            label: t("filters.medium"),
            name: SelectorNames.Medium,
            options: analyticsApis.utmParamsApi.data
              ? analyticsApis.utmParamsApi.data.utm_mediums.map((medium) => ({
                  value: medium.UTM_MEDIUM_ID.toString(),
                  label: medium.UTM_MEDIUM,
                  key: medium.UTM_MEDIUM_ID,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Medium
              ),
            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#59a74f",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Campaign:
          return {
            label: t("filters.campaign"),
            name: SelectorNames.Campaign,
            options: analyticsApis.utmParamsApi.data
              ? analyticsApis.utmParamsApi.data.utm_campaigns.map(
                  (campaign) => ({
                    value: campaign.UTM_CAMPAIGN_ID.toString(),
                    label: campaign.UTM_CAMPAIGN,
                    key: campaign.UTM_CAMPAIGN_ID,
                  })
                )
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Campaign
              ),
            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#59a74f",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Term:
          return {
            label: t("filters.term"),
            name: SelectorNames.Term,
            options: analyticsApis.utmParamsApi.data
              ? analyticsApis.utmParamsApi.data.utm_terms.map((term) => ({
                  value: term.UTM_TERM_ID.toString(),
                  label: term.UTM_TERM,
                  key: term.UTM_TERM_ID,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Term
              ),
            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#59a74f",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Content:
          return {
            label: t("filters.content"),
            name: SelectorNames.Content,
            options: analyticsApis.utmParamsApi.data
              ? analyticsApis.utmParamsApi.data.utm_contents.map((content) => ({
                  value: content.UTM_CONTENT_ID.toString(),
                  label: content.UTM_CONTENT,
                  key: content.UTM_CONTENT_ID,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Content
              ),

            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#59a74f",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Device:
          return {
            label: t("filters.device"),
            name: SelectorNames.Device,
            options: analyticsApis.utmParamsApi.data
              ? analyticsApis.utmParamsApi.data.devices.map(
                  (device: string, index: number) => ({
                    value: device,
                    label: device,
                    key: index,
                  })
                )
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Device
              ),
            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#59a74f",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Url:
          return {
            label: t("filters.url"),
            name: SelectorNames.Url,
            options: analyticsApis.utmParamsApi.data
              ? analyticsApis.utmParamsApi.data.urls.map((url) => ({
                  value: url.URL_ID.toString(),
                  label: url.URL,
                  key: url.URL_ID,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Url
              ),
            labelColor: selector?.labelColor || "#59a74f",
            dependentOn: SelectorNames.Manufacturer,
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.ParentBrand:
          return {
            label: t("filters.brand"),
            name: SelectorNames.ParentBrand,
            options: (analyticsApis.parentBrandsApi?.data || []).map(
              (brand) => ({
                value: brand.ID.toString(),
                label: brand.BRAND,
                key: brand.ID,
              })
            ),
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [
                  SelectorNames.Products,
                  SelectorNames.SubBrand,
                ],
                event,
                SelectorNames.ParentBrand
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.SubBrand:
          return {
            label: t("filters.subbrand"),
            name: SelectorNames.SubBrand,
            options: (analyticsApis.subBrandsApi?.data || []).map((brand) => ({
              value: brand.ID.toString(),
              label: brand.BRAND,
              key: brand.ID,
            })),
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [SelectorNames.Products],
                event,
                SelectorNames.SubBrand
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Label:
          return {
            label: t("filters.label"),
            name: SelectorNames.Label,
            options: (analyticsApis.labelsApi?.data || []).map((label) => ({
              value: label.ID,
              label: label.name,
              key: label.ID,
            })),
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Label
              ),
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Seller:
          return {
            label: t("filters.seller"),
            name: SelectorNames.Seller,
            options: (analyticsApis.sellerApi?.data || []).map((s, i) => ({
              value: s.SHOP_ID_EXT.toString(),
              label: s.SHOP_NAME || s.SHOP_ID_EXT.toString(),
              key: i,
            })),
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Seller
              ),
            defaultValue: selector?.defaultValue,
          };
        case SelectorNames.Market:
          return {
            label: t("filters.market"),
            name: SelectorNames.Market,
            options: [
              {
                value: 0,
                label: "My Brands",
                key: 0,
              },
            ],
            disabled: selector.disabled,
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Market
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
          };
        default:
          return {
            label: selector.name,
            name: selector.name as SelectorNames,
            options: [],
          };
      }
    });
  }, [
    analyticsApis.productsApi.data,
    manufacturersData,
    analyticsApis,
    handleManufacturerChange,
    selectorsConfig,
    t,
  ]);

  useEffect(() => {
    /**
     * If filters manually changed, await selector option changes.
     * If selected option no longer in option, change to default, except for `manufacturer`.
     */
    if (hasManuallyChanged.current) {
      hasManuallyChanged.current = false;

      const newSelectors = commonSelectorsConfig.reduce((acc, csc) => {
        const oldValue =
          currentSelectors[csc.name as keyof AnalyticsRequestBody];

        if (csc.name === SelectorNames.Manufacturer) {
          return acc;
        }

        if (!csc.options.find((o) => o.value === oldValue)) {
          return {
            ...acc,
            [csc.name]:
              defaultSelectors[csc.name as keyof AnalyticsRequestBody],
          };
        }
        return acc;
      }, {});
      const completeSelectors = { ...currentSelectors, ...newSelectors };

      setCurrentSelectors(completeSelectors);
      fetchDependentAPIs(completeSelectors);
    }
  }, [commonSelectorsConfig, currentSelectors]);

  return {
    isLoading,
    currentSelectors,
    setCurrentSelectors,
    commonSelectorsConfig,
    handleManufacturerChange,
  };
};
