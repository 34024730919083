import React, { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import {
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { TreeTable } from "primereact/treetable";
import { t } from "i18next";
import { spacing } from "@mui/system";
import { Column } from "primereact/column";
import { BuyNowTransactionsParams } from "@/api/useBuyNowTransactions";
import useData from "@/hooks/auth/useData";
import { TextField, InputAdornment, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BuyNowTransactionNode } from "./types";
import {
  COLUMN_WIDTHS,
  TABLE_MIN_WIDTH,
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_OPTIONS,
} from "./consts";

const Typography = styled(MuiTypography)(spacing);

export const BuyNowTransactions = () => {
  const [globalFilter, setGlobalFilter] = useState("");
  const { buyNowTransactionsApi, selectedManufacturers, user } = useData();

  const nodes = useMemo<BuyNowTransactionNode[]>(() => {
    return (buyNowTransactionsApi?.data || []).map((item) => ({
      key: item.key,
      data: {
        timestamp: item.data.timestamp || null,
        timelead: item.data.timelead || null,
        POS: item.data.POS || "",
        SKU: item.data.SKU || "",
        Product: item.data.Product || "",
        items: item.data.items || 0,
        value: item.data.value || 0,
      },
      children: item.children?.map((child) => ({
        key: child.key,
        data: {
          timestamp: child.data.timestamp || null,
          timelead: child.data.timelead || null,
          POS: child.data.POS || "",
          SKU: child.data.SKU || "",
          Product: child.data.Product || "",
          items: child.data.items || 0,
          value: child.data.value || 0,
        },
      })),
    }));
  }, [buyNowTransactionsApi?.data]);

  const dateTemplate = (node: BuyNowTransactionNode) =>
    node.data.timestamp ? node.data.timestamp.toString() : "";

  const timeleadTemplate = (node: BuyNowTransactionNode) =>
    node.data.timelead ? node.data.timelead.toString() : "";

  const posTemplate = (node: BuyNowTransactionNode) => node.data.POS || "";
  const skuTemplate = (node: BuyNowTransactionNode) => node.data.SKU || "";
  const productTemplate = (node: BuyNowTransactionNode) =>
    node.data.Product || "";

  useEffect(() => {
    const id = selectedManufacturers.map((m) => m?.ID);

    if (buyNowTransactionsApi && id) {
      // TODO: change with global filter
      // Date is unix timestamp in seconds
      const params: BuyNowTransactionsParams = {
        manufacturer: id,
        from_date: Math.floor((Date.now() - 30 * 24 * 60 * 60 * 1000) / 1000),
        to_date: Math.floor(Date.now() / 1000),
      };
      buyNowTransactionsApi.execute(params);
    }
  }, [selectedManufacturers]);

  const getHeader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginBottom: "1rem",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}
        >
          <InfoOutlinedIcon
            sx={{ color: "text.secondary", fontSize: "0.875rem" }}
          />
          <Typography
            variant="caption"
            sx={{
              color: "text.secondary",
              fontSize: "0.75rem",
              marginRight: "0.5rem",
            }}
          >
            {t("pages.buyNow.transactions.description")}
          </Typography>
        </Box>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Global Search"
          onChange={(e) => setGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };

  const header = getHeader();

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TreeTable
            value={nodes}
            paginator
            rows={ROWS_PER_PAGE}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            tableStyle={{ minWidth: TABLE_MIN_WIDTH }}
            globalFilter={globalFilter}
            filterMode="lenient"
            columnResizeMode="fit"
            header={header}
            sortField="timestamp"
            sortOrder={-1}
          >
            <Column expander style={{ width: COLUMN_WIDTHS.EXPANDER }} frozen />
            <Column
              field="timestamp"
              header={t("tableColumns.timestamp")}
              body={dateTemplate}
              sortable
              sortField="timestamp"
              style={{ width: COLUMN_WIDTHS.TIMESTAMP }}
              frozen
            />
            <Column
              field="POS"
              header={t("tableColumns.POS")}
              body={posTemplate}
              style={{ width: COLUMN_WIDTHS.POS }}
              frozen
            />
            <Column
              field="SKU"
              header={t("tableColumns.SKU")}
              body={skuTemplate}
              style={{ width: COLUMN_WIDTHS.SKU }}
              frozen
            />
            <Column
              field="Product"
              header={t("tableColumns.product")}
              body={productTemplate}
              style={{ width: COLUMN_WIDTHS.PRODUCT }}
              frozen
            />
            <Column
              field="items"
              header={t("tableColumns.items")}
              style={{ width: COLUMN_WIDTHS.ITEMS }}
              frozen
            />
            <Column
              field="value"
              header={t("tableColumns.value")}
              style={{ width: COLUMN_WIDTHS.VALUE }}
              frozen
            />
            <Column
              field="timelead"
              header={t("tableColumns.timelead")}
              body={timeleadTemplate}
              style={{ width: COLUMN_WIDTHS.TIMELEAD }}
              frozen
            />
          </TreeTable>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
