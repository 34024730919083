import { getTranslatedHeaderName } from "./utils";

export const PREVIEW_URL = import.meta.env.VITE_PREVIEW_URL || '';
export const na = getTranslatedHeaderName('tableColumns.notAvailable', 'n.a.');
export const green = 'rgb(86, 166, 75)';
export const red = 'rgb(224, 47, 68)';
export const yellow = 'rgb(242, 204, 12)';
export const darkRed = 'rgb(173, 3, 23)';
export const gray = 'rgb(201, 201, 201)';
export const orange = 'rgb(255, 120, 10)';
export const black = 'rgb(0, 0, 0)';

export const baseColors = [green, red];

export const statusTranslations = {
  'active': getTranslatedHeaderName('tableColumns.active', 'Active'),
  'discontinued': getTranslatedHeaderName('tableColumns.discontinued', 'Discontinued'),
  'deleted': getTranslatedHeaderName('tableColumns.deleted', 'deleted')
};
  
export const avTranslations = {
  1: getTranslatedHeaderName('tableColumns.inStock', 'in stock'),
  2: getTranslatedHeaderName('tableColumns.limited', 'limited availability'),
  3: getTranslatedHeaderName('tableColumns.outOfStock', 'out of stock'),
  4: getTranslatedHeaderName('tableColumns.outOfTrade', 'out of trade'),
  5: getTranslatedHeaderName('tableColumns.unknown', 'unknown'),
  6: getTranslatedHeaderName('tableColumns.noBuyBox', 'No buy box'),
};


export const yesNoTranslations = {
  'Yes': getTranslatedHeaderName('tableColumns.yes', 'Yes'),
  'No': getTranslatedHeaderName('tableColumns.no', 'No')
};