import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { getTitleByPathname } from "@/consts/navItems";
import { useLocation } from "react-router-dom";
import {
  Button,
  Grid,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";
import SupportModal from "../support-modal/SupportModal";
import TimeRangePicker from "../TimeRangePicker/TimeRangePicker";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const StyledButton = styled(Button)`
  background-color: #e0e0e0;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  padding: 4px 8px;
  margin-right: 24px;

  &:hover {
    background-color: #264d91;
    color: #fff;
  }
`;

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const { t } = useTranslation();
  const [openSupportModal, setOpenSupportModal] = React.useState(false);

  const location = useLocation();
  const title = getTitleByPathname(location.pathname);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={1}>
        <Toolbar
          sx={{
            paddingLeft: { xs: 0, md: "12px" },
            paddingRight: { xs: 0, md: "12px" },
          }}
        >
          <Grid container alignItems="center" justifyContent="flex-end">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Helmet title={title} />
              <Typography variant="h4" color={"#000000"}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <StyledButton
                    variant="contained"
                    onClick={() => setOpenSupportModal(true)}
                  >
                    {t("general.support.button")}
                  </StyledButton>
                </Grid>
                <Grid item sx={{ marginRight: "16px" }}>
                  <TimeRangePicker />
                </Grid>
                <Grid item>
                  <NavbarUserDropdown />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <SupportModal
        open={openSupportModal}
        onClose={() => setOpenSupportModal(false)}
      />
    </React.Fragment>
  );
};

export default withTheme(Navbar);
