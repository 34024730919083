import { Label } from "@/api/setup/types";
import { Box, Typography } from "@mui/material";

type LabelsCellProps = {
  labels: Label[];
};

const LabelsCell = (params: LabelsCellProps) => {
  return params.labels.length > 0 ? (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ width: "100%", height: "100%" }}
    >
      {params.labels.map((label) => (
        <Box
          key={label.label_id}
          display="flex"
          alignItems="center"
          sx={{
            mr: "8px",
          }}
        >
          <Box
            sx={{
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: label.label_color,
              mr: "4px",
            }}
          />
          <Typography fontWeight={500}>{label.label_name}</Typography>
        </Box>
      ))}
    </Box>
  ) : (
    "n.a."
  );
};

export default LabelsCell;
