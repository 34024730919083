import { HeadCell } from "@/components/tables/AdvancedTable";
import { useTranslation } from "react-i18next";
import { SelectorNames } from "@/components/FiltersPanel/consts";
import { DataState } from "@/types/dataContext";
import { SelectorDef } from "@/components/FiltersPanel/types";

export const queryParams = [
  "brand",
  "products",
  "subbrand",
  "merchant",
  "focus",
  "url",
  "device",
  "term",
  "source",
  "medium",
  "campaign",
  "content",
  "days",
];

export const moreSelectors = [SelectorNames.Source, SelectorNames.Medium, SelectorNames.Campaign, SelectorNames.Term, SelectorNames.Content, SelectorNames.Device, SelectorNames.Url];

export const selectorsConfig: Array<SelectorDef> = [
  { name: "merchant"},
  { name: "parentBrand", dependentFilters: [SelectorNames.Products, SelectorNames.SubBrand]},
  { name: "subbrand", dependentFilters: [SelectorNames.Products]},
  { name: "products"},
  { name: "focus", dependentFilters: [SelectorNames.Products, SelectorNames.ParentBrand, SelectorNames.SubBrand], defaultValue: 0 },
  { name: "source"},
  { name: "medium"},
  { name: "campaign"},
  { name: "term"},
  { name: "content"},
  { name: "device"},
  { name: "url", defaultValue: 'All'}
];

export const dependentAPIs: (keyof DataState)[] = [
  "analyticsGraphApi",
  "productLeadsApi",
  "productsVisitsApi",
  "merchantsLeadsApi",
  "merchantsTransactionsApi",
  "brandPagePerformanceApi",
  "kpiTransactionsApi",
  "kpiBrandPerformanceApi",
  "kpiMerchantsLeadsApi",
];

export const useTableCells = () => {
  const { t } = useTranslation();

  const productLeadsCells: Array<HeadCell> = [
    { id: "LEADS", label: t("tableColumns.leads") },
    { id: "CR_numeric", label: t("tableColumns.CTR") },
    { id: "POS", label: t("tableColumns.POS") },
    { id: "PRODUCT", label: t("tableColumns.product") },
    { id: "PZN", label: t("tableColumns.PZN") },
  ];

  const productVisitsCells: Array<HeadCell> = [
    { id: "visits", label: t("tableColumns.visits") },
    { id: "product", label: t("tableColumns.product") },
  ];

  const brandVisitsCells: Array<HeadCell> = [
    { id: "Visits", label: t("tableColumns.visits") },
    { id: "Brand", label: t("tableColumns.brand") },
  ];

  const merchantsCells: Array<HeadCell> = [
    { id: "leads", label: t("tableColumns.leads") },
    { id: "CR_numeric", label: t("tableColumns.CTR") },
    { id: "merchant", label: t("tableColumns.merchant") },
  ];

  const merchantsTransactionsCells: Array<HeadCell> = [
    { id: "transactions", label: "Transactions" },
    { id: "CR_numeric", label: t("tableColumns.CR") },
    { id: "merchant", label: t("tableColumns.merchant") },
  ];

  const productOpenedCells: Array<HeadCell> = [
    { id: "LayerOpened", label: t("tableColumns.opened") },
    { id: "CR_numeric", label: t("tableColumns.OR") },
    { id: "Brand", label: t("tableColumns.brand") },
  ];

  return {
    productLeadsCells,
    productVisitsCells,
    brandVisitsCells,
    merchantsCells,
    merchantsTransactionsCells,
    productOpenedCells,
  };
};

export const usePromotionsTable = () => {
  const { t } = useTranslation();

  return [
    { id: "name", label: t("tableColumns.name") },
    { id: "type", label: t("tableColumns.type") },
    { id: "status", label: t("tableColumns.status") },
    { id: "startDate", label: t("tableColumns.startDate") },
    { id: "endDate", label: t("tableColumns.endDate") },
    { id: "actions", label: t("tableColumns.actions") },
  ];
};
