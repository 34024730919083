import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { InlineWidget } from "react-calendly";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useContext } from "react";
import { DialogActions, IconButton, Typography } from "@mui/material";
import { DataContext } from "@/context/DataContext";
import { useTranslation } from "react-i18next";

interface SupportModalProps {
  open: boolean;
  onClose: () => void;
}

const SupportModal: FC<SupportModalProps> = ({ open, onClose }) => {
  const { user } = useContext(DataContext);
  const { t } = useTranslation();

  const defaultURL = "https://calendly.com/maven360-team/support-call";
  const url = user?.company_csm || defaultURL;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ width: "520px", margin: "auto" }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <Typography variant="h3" sx={{ paddingRight: "20px" }}>
          {t("general.support.title")}
        </Typography>
        <DialogActions sx={{ position: "absolute", right: 0, top: 3 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <InlineWidget
          url={url}
          styles={{ minWidth: "320px", height: "700px" }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SupportModal;
