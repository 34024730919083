import ContentContainer from "@/components/contentContainer";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { Paper } from "@mui/material";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelsResponse } from "../../../api/setup/types";
import { useColumns } from "@/hooks/useColumnsData";
import useData from "@/hooks/auth/useData";

const Labels = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const { selectedManufacturers, setupLabelsApi, isLoading } = useData();

  const manufacturerIds = useMemo(
    () => selectedManufacturers.map((m) => m.ID),
    [selectedManufacturers]
  );

  const rows = useMemo(() => {
    return (setupLabelsApi.data || []).map((row) => ({
      ...row,
      UPDATED: row.updated,
      id: row.ID,
    }));
  }, [setupLabelsApi.data]);

  useEffect(() => {
    if (!manufacturerIds.length) return;

    setupLabelsApi.execute({
      manufacturer: manufacturerIds,
    });
  }, [manufacturerIds]);

  const {
    updatedDateColumn,
    labelColumn,
    commentColumn,
    itemsColumn,
    typeColumn,
    collaborationColumn,
    ownerLabelsColumn,
  } = useColumns();

  const columns: GridColDef<LabelsResponse>[] = [
    labelColumn,
    commentColumn,
    itemsColumn,
    typeColumn,
    collaborationColumn,
    ownerLabelsColumn,
    updatedDateColumn,
    // actionsColumn,
  ];

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          rows={rows}
          columns={columns}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          selectedRows={selectedRows}
          loading={isLoading}
          filterBarType={3}
          hasCheckboxes={true}
          title={t("pages.labels.title") as string}
        />
      </Paper>
    </ContentContainer>
  );
};

export default Labels;
